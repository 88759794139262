<template>
  <b-overlay :show="loading" opacity="0.5">
    <div>
      <list-table
        :records="transactions" 
        :columns="tableColumns"
        :total-records="totalRecords"
        :search-filter.sync="memberFilter"
        :current-page-number.sync="currentPage"
        :show-export-button="true"
        :show-search="true"
        :use-auto-complete-search="true"
        @export="onExportTransactions"
      >
        <template #filters>
          <b-row>
            <b-col cols="12" md="2" class="">
              <v-select
                class="filter-select select-size-md"
                placeholder="Church"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="churchFilter"
                :options="churchOptions"
                :reduce="val => val.value"
                :disabled="loading"
                @input="(val) => churchFilter = val"
                />
            </b-col>

            <b-col cols="12" md="2" class="">
              <v-select
                class="filter-select select-size-md"
                placeholder="Member"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="memberFilter"
                :options="memberOptions"
                :reduce="val => val.value"
                :disabled="loading"
                @input="(val) => memberFilter = val"
                />
            </b-col>

            <b-col cols="12" md="2" class="">
              <v-select
                class="filter-select select-size-md"
                placeholder="Offering Type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="offeringTypeFilter"
                :options="offeringTypeOptions"
                :reduce="val => val.value"
                :disabled="loading"
                @input="(val) => offeringTypeFilter = val"
              />
            </b-col>
            
            <b-col cols="12" md="2">
              <b-form-datepicker
                v-model="dateFrom"
                :close-button="true"
                reset-button
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                placeholder="From"
                class="mr-2"
              />
            </b-col>

            <b-col cols="12" md="2" class="mb-md-0 mb-2">
              <b-form-datepicker
                v-model="dateTo"
                placeholder="To"
                reset-button
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                :min="dateFrom"
              />
            </b-col>
          </b-row>
        </template>

        <!-- <template #legend>
          <div class="">
            <p class="text-muted text-right" style="font-size: 10px">
              Payment Legend
            </p>
            <div class="d-flex flex-row-reverse" style="gap: 10px">
              <div>
                <span
                  class="bg-danger rounded"
                  style="height: 10px; width: 10px; display: inline-block"
                ></span>
                Not Paid
              </div>

              <div>
                <span
                  class="bg-warning rounded"
                  style="height: 10px; width: 10px; display: inline-block"
                ></span>
                50% Paid
              </div>

              <div>
                <span
                  class="bg-info rounded"
                  style="height: 10px; width: 10px; display: inline-block"
                ></span>
                80% Paid
              </div>

              <div>
                <span
                  class="bg-success rounded"
                  style="height: 10px; width: 10px; display: inline-block"
                ></span>
                Fully Paid
              </div>
            </div>
          </div>
        </template> -->

        <template #cell(member)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="getValueFromSource(data, 'item.member.avatar.path')"
                :text="sentenceCase(avatarText(`${data.item.member.full_name}`))"
                :variant="`light-${resolveUserRoleVariant(data.item.member.user_type)}`"
                :to="{ name: 'admin-user-single', params: { id: data.item.member._id } }"
              />
            </template>
            <b-link
              :to="{ name: 'admin-user-single', params: { id: data.item.member._id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ sentenceCase(getValueFromSource(data, 'item.member.full_name')) }}
            </b-link>
            <span v-if="data.item.member.email" class="text-muted">{{ data.item.member.email }}</span><br />
            <span v-if="data.item.member.phone" class="text-muted">{{ data.item.member.phone }}</span>
          </b-media>
        </template>

        <template #cell(church)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="getValueFromSource(data, 'item.church.avatar.path')"
                :text="sentenceCase(avatarText(`${data.item.church.full_name}`))"
                :variant="`light-${resolveUserRoleVariant(data.item.church.user_type)}`"
                :to="{ name: 'admin-update-church', params: { id: data.item.church._id } }"
              />
            </template>
            <b-link
              :to="{ name: 'admin-update-church', params: { id: data.item.church._id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ sentenceCase(getValueFromSource(data, 'item.church.full_name')) }}
            </b-link>
            <span class="text-muted">{{ formatChurchLevel(data.item.church.church_level) }}</span><br />
          </b-media>
        </template>

        <template #cell(church_location)="data">
          <p class="mb-2">
            {{ getChurchLocationByChurchLevel(data.item.church_level, data.item) }}
          </p>
        </template>

        <template #cell(offering_type)="data">
          <span>
            {{ data.item.offering_type.title }}
          </span>
        </template>

        <template #cell(meta)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(getValueFromSource(data.item, 'meta.church_approval'))}`"
            class="text-capitalize"
          >
            {{ getUserStatusText(getValueFromSource(data.item, 'meta.church_approval')) }}
          </b-badge>
        </template>
      </list-table>
    </div>
  </b-overlay>
</template>

<script>
import {
  BMedia, BAvatar, BButton, BFormFile,
  BBadge, BDropdown, BDropdownItem, BPagination,
  BOverlay, BCard, BRow, BCol, BAlert, BLink, BTable,
  BInputGroup, BInputGroupAppend, BFormInput, BFormDatepicker
} from 'bootstrap-vue'

import { get, debounce } from "lodash"

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import FileDownload from "js-file-download";
import ListTable from "@/@core/components/ListTable/ListTable.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const watchHandler = {
  handler(){
    this.debouncedFetchData()
  }, 
  immediate: false
}

const watchHandlerRequiresPageReset = {
  handler(){
    this.currentPage = 1
    this.debouncedFetchData()
  }, 
  immediate: false
} 

export default {
  components: {
    ListTable,

    BOverlay,
    BCard,
    BTable,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BLink,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BFormFile,
    BFormInput,
    BInputGroup, 
    BFormDatepicker,
    BInputGroupAppend, 

    vSelect,
  },
  directives: {
    Ripple,
  },
  data(){
    return {
      loading: false,
      showFilter: false,
      showListTable: false,
      
      dateTo: null,
      dateFrom: null,
      searchFilter: null,
      churchFilter: null,
      memberFilter: null,
      churchLevelFilter: null,
      offeringTypeFilter: null,

      transactions: [],
      churchOptions: [],
      memberOptions: [],
      offeringTypeOptions: [],

      tableColumns: [
        { key: 'member', stickyColumn: true, sortable: false, label: 'Member' },
        { key: 'church', stickyColumn: true, sortable: false, label: 'Church Admin' },
        { key: 'church_level', sortable: false, label: 'Church Level', formatter: val => this.formatChurchLevel(val) },
        { key: 'church_location', sortable: false, label: 'Church' },
        { key: 'church_subaccount_code', sortable: false, label: 'Account Code' },
        { key: 'offering_type', sortable: false, label: 'Offering' },
        { key: 'amount', sortable: false, formatter: val => `GH₵ ${this.formatMoney(val)}` },
        { key: 'reference', sortable: false },
        { key: 'created', label: 'Date', sortable: false, formatter: val => `${this.formatDate(val)}`, },
      ],
      debouncedFetchData: () => {}
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
    dataMeta(){
      const localItemsCount = this.users.length || 0;
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalRecords,
      }
    }
  },
  watch: {
    perPage: {
      handler(){
        this.currentPage = 1;
        this.debouncedFetchData()
      }, 
      immediate: false
    },
    currentPage: watchHandler,
    dateTo: watchHandlerRequiresPageReset,
    dateFrom: watchHandlerRequiresPageReset,
    searchFilter: watchHandlerRequiresPageReset,
    memberFilter: watchHandlerRequiresPageReset,
    churchFilter: watchHandlerRequiresPageReset,
    churchLevelFilter: watchHandlerRequiresPageReset,
    offeringTypeFilter: watchHandlerRequiresPageReset,
  },
  created(){
    this.debouncedFetchData = debounce(this.fetchAllData, 500);

    const { 
      page = 1, 
      search = "", 
      church_level = "", 
      church_id = "", 
      member_id = "", 
      offering_type = "",
      from = "",
      to = "",
      limit = this.perPage 
    } = this.$route.query;

    this.currentPage = +page;
    this.searchFilter = search;
    this.churchLevelFilter = church_level;
    this.churchFilter = church_id;
    this.memberFilter = member_id;
    this.offeringTypeFilter = offering_type;
    this.dateFrom = from;
    this.dateTo = to;
    this.perPage = +limit;

    this.fetchChurches();
    this.fetchMembers();
    this.fetchOfferingTypes();
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true; 

        const query = {
          to: this.dateTo,
          limit: this.perPage,
          from: this.dateFrom,
          page: this.currentPage,
          search: this.searchFilter,
          church_id: this.churchFilter,
          member_id: this.memberFilter,
          church_level: this.churchLevelFilter,
          offering_type: this.offeringTypeFilter,
        }

        this.$router.push({ query }).catch(() => {});

        const request = await this.useJwt().adminService.fetchTransactions(query);
        const { data, pagination } = request.data;

        this.totalRecords = pagination.totalRecords
        this.perPage = pagination.limit;
        this.transactions = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
        this.showListTable = true;
      }
    },
    async fetchChurches() {
      try {
        this.loading = true; 

        const query = {
          page: 1,
          limit: 10000,
          status: 'approved',
        }

        const request = await this.useJwt().adminService.fetchChurches(query);
        const { data } = request.data;

        this.churchOptions = data.map(opt => ({
          label: `Church Of Pentecost - ${this.getChurchLocationByChurchLevel(opt.church_level, opt)}`,
          value: opt.id
        }));
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
        this.showListTable = true;
      }
    },
    async fetchMembers() {
      try {
        this.loading = true; 

        const query = {
          page: 1,
          limit: 10000,
        }

        const request = await this.useJwt().adminService.fetchChurchMembers(query);
        const { data } = request.data;

        this.memberOptions = data.map(opt => ({
          label: `${opt.full_name} | ${opt.phone}`,
          value: opt.id
        }));
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
        this.showListTable = true;
      }
    },
    async fetchOfferingTypes() {
      try {
        this.loading = true; 

        const query = {
          limit: 1000,
          page: 1,
          status: 'active',
        }

        const response = await this.useJwt().adminService.fetchOfferingTypes(query)

        const { data } = response.data;

        this.offeringTypeOptions = data.map(opt => ({
          label: opt.title,
          value: opt.id
        }));
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
        this.showListTable = true;
      }
    },
    async onExportTransactions(){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Export?',
          text: "This will export data based on current filters.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        const query = {
          to: this.dateTo,
          from: this.dateFrom,
          search: this.searchFilter,
          offering_type: this.offeringTypeFilter,
        }

        const response = await this.useJwt().adminService.exportTransactions(query);
        FileDownload(response.data, "transactions.csv");
      } catch (error) {
        // const error_message = get(error, "response.statusText") || error.message;
        this.$nextTick(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'There is no data to export'
            },
          });
        })
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-select.scss';
  .width-100 {
    width: 100px;
  }
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  .loan-list-tb-row {
    cursor: pointer !important;
  }
</style>

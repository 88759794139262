<template>
  <b-row>
    <b-col cols="12" md="6">
      <validation-observer ref="blogPostForm" #default="{invalid}">
        <b-form ref="form" @submit.prevent="onCreateOrUpdate">
          <b-card
            class="blog-edit-wrapper"
          >
            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Title"
                  label-for="title"
                  class="mb-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Title"
                    rules="required"
                  >
                    <b-form-input
                      id="title"
                      v-model="offeringTypeData.title"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label="Supported Levels"
                  label-for="supported-levels"
                  class="mb-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Supported Levels"
                    rules="required"
                  >
                    <v-select
                      id="supported-levels"
                      v-model="offeringTypeData.supported_levels"
                      multiple
                      clearable
                      :close-on-select="false"
                      :options="churchLevelOptions"
                      :reduce="val => val.value"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                class="mt-50"
              >
                <b-button
                  class="mb-5"
                  style="width: 200px"
                  variant="primary"
                  block
                  type="submit"
                  :disabled="invalid || disableForm"
                >
                  {{ isUpdate ? 'Update Offering Type' : 'Create Offering Type' }}
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, 
  BMedia, 
  BForm, 
  BRow, 
  BCol, 
  BImg, 
  BLink, 
  BAvatar, 
  BButton,
  BCardText, 
  BFormFile, 
  BMediaAside, 
  BMediaBody, 
  BFormGroup, 
  BFormInput, 
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { cloneDeep, get } from "lodash";

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const rawOfferingTypeData = {
  title: "",
  supported_levels: []
}
export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    updatingOfferingType: {
      type: [Object, null, undefined],
      default: null,
      required: false
    },
  },
  data() {
    return {
      loading: false,

      offeringTypeData: cloneDeep(rawOfferingTypeData),
      errorMessage: "",


      required
    }
  },
  computed: {
    isUpdate(){
      return !!this.updatingOfferingType
    },
    disableForm(){
      return this.isUpdate ? !this.can(["offering_types:CanUpdate"]) : !this.can(["offering_types:CanAddNew"])
    }
  },
  watch: {
    updatingOfferingType: {
      handler(update){
        if (update){
          this.offeringTypeData = cloneDeep(update)
        }
      }, 
      deep: true, 
      immediate: true
    }
  },
  created() {},
  methods: {
    async onCreateOrUpdate(){
      try {
        this.loading = true;
        
        const success = await this.$refs.blogPostForm.validate();
        if (!success){
          return;
        }

        const payload = {
          title: this.offeringTypeData.title,
          supported_levels: this.offeringTypeData.supported_levels
        }

        if (this.isUpdate){
          this.$emit("update", payload)
        } else {
          this.$emit("create", payload)
        }
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>

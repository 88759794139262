<template>
  <section>
    <CreatedAuditTrails />
  </section>
</template>

<script>
import { BTab, BTabs } from "bootstrap-vue";
import CreatedAuditTrails from "@/pages/admin/logs/audit-trail/created/index.vue";

export default {
  name: "AuditTrail",
  components: {
    BTabs,
    BTab,
    CreatedAuditTrails
  }
}
</script>

<style scoped>

</style>

<template>
  <b-overlay :show="loading" opacity="0.5">
    <list-table 
    :records="users" 
    :columns="tableColumns"
    :total-records="totalRecords"
    :current-page-number.sync="currentPage"
    :search-filter.sync="searchFilter"
    :show-export-button="can('users:CanExport')"
    :show-import-button="false"
    @row-clicked="onRowClicked"
    >
    <template v-if="can('admin:CanAddNew')" #action_button>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="primary"
        :to="{ name: 'admin-new-user' }"
      >
        <span class="align-middle text-lg">New User</span>
      </b-button>
    </template>

    <template #filters>
      <b-row>
        <b-col cols="12" md="2" class="">
          <v-select
            class="filter-select select-size-md"
            placeholder="Status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            :reduce="val => val.value"
            :disabled="loading"
            @input="(val) => statusFilter = val"
            />
        </b-col>
      </b-row>
    </template>

    <template #cell(avatar)="data">
        <b-media vertical-align="center">
        <template #aside>
            <b-avatar
            size="32"
            :src="getValueFromSource(data, 'item.avatar.path')"
            :text="sentenceCase(avatarText(`${data.item.full_name}`))"
            :variant="`light-${resolveUserRoleVariant(data.item.user_type)}`"
            :to="{ name: 'admin-single', params: { id: data.item._id } }"
            />
        </template>
        <b-link
            :to="{ name: 'admin-single', params: { id: data.item._id } }"
            class="font-weight-bold d-block text-nowrap"
        >
            {{ sentenceCase(getValueFromSource(data, 'item.full_name')) }}
        </b-link>
        <span class="text-muted">{{ data.item.email }}</span><br />
        <span class="text-muted">@{{ data.item.phone }}</span>
        </b-media>
    </template>

    <template #cell(mfa_conf)="data">
      <b-badge
        pill
        :variant="`light-${resolveStatusVariant(getMFAStatus(data.item))}`"
        class="text-capitalize"
      >
        {{ getUserStatusText(getMFAStatus(data.item)) }}
      </b-badge>
    </template>

    <template #cell(national_ID)="data">
        <span>{{ getValueFromSource(data, 'item.national_ID') || '-' }}</span>
    </template>

    <template #cell(status)="data">
        <b-badge
          pill
          :variant="`light-${resolveStatusVariant(data.item.status)}`"
          class="text-capitalize"
        >
          {{ getUserStatusText(data.item.status) }}
        </b-badge>
    </template>

    <template #cell(actions)="data">
      <b-dropdown
        variant="link"
        no-caret
        :right="$store.state.appConfig.isRTL"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>

        <b-dropdown-item :to="{ name: 'admin-single', params: { id: data.item._id } }">
          <feather-icon icon="FileTextIcon" />
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>

        <b-dropdown-item v-if="data.item.status === 'pending_confirmation'" @click="resendWelcomeEmail(data.item)">
          <feather-icon icon="FileTextIcon" />
          <span class="align-middle ml-50">Resend Welcome Email</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>
    </list-table>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BRow, BCol, BAlert, BLink, BTable,
  BMedia, BAvatar, BButton, BFormFile,
  BBadge, BDropdown, BDropdownItem, BPagination,
  BInputGroup, BInputGroupAppend, BFormInput
} from 'bootstrap-vue'
import { get, debounce } from "lodash"

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import ListTable from "@/@core/components/ListTable/ListTable.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const watchHandler = {
  handler(){
    this.debouncedFetchUsersData()
  }, 
  immediate: false
}
export default {
  components: {
    ListTable,

    BOverlay,
    BCard,
    BTable,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BLink,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BFormFile,
    BInputGroup, 
    BInputGroupAppend, 
    BFormInput,

    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      showFilter: false,
      showListTable: false,
      statusFilter: null,
      searchFilter: "",
      users: [],
      tableColumns: [
        { key: 'avatar', stickyColumn: true, sortable: false, label: 'User' },
        { key: 'status', sortable: false },
        { key: 'created', label: 'Date', sortable: false, formatter: val => `${this.formatDate(val)}`, },
        { key: 'mfa_conf', sortable: false, label: 'MFA Status' },
        { key: 'actions' },
      ],
      statusOptions: [
        { label: 'Active', value: 'active' },
        { label: 'In-Active', value: 'inactive' },
        { label: 'Blocked', value: 'blocked' },
        { label: 'Pending Email Verification', value: 'pending_confirmation' },
        { label: 'Pending Phone Verification', value: 'pending_sms' }
      ],
      debouncedFetchUsersData: () => {}
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
    dataMeta(){
      const localItemsCount = this.users.length || 0;
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalRecords,
      }
    }
  },
  watch: {
    perPage: {
      handler(){
        this.currentPage = 1;
        this.debouncedFetchUsersData()
      }, 
      immediate: false
    },
    currentPage: watchHandler,
    statusFilter: watchHandler,
    searchFilter: watchHandler,
  },
  created(){
    this.debouncedFetchUsersData = debounce(this.fetchAllData, 500);

    const { 
      status = "active", 
      page = 1, 
      search = "", 
      limit = this.perPage 
    } = this.$route.query;

    this.currentPage = +page;
    this.searchFilter = search;
    this.statusFilter = status;
    this.perPage = +limit;
  },
  methods: {
    async resendWelcomeEmail(user) {
      console.log("resending email for", user);
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Resend Welcome Email?',
          text: `You are about to resend the welcome email to ${user.email}?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, send!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        await this.useJwt().adminService.resendAdminAccountConfirmationEmail(user._id);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: "Account confirmation email resent successfully"
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async fetchAllData() {
      try {
        this.loading = true; 

        const query = {
          limit: this.perPage,
          page: this.currentPage,
          status: this.statusFilter,
          search: this.searchFilter
        }
        this.$router.push({ query }).catch(() => {});

        const response = await this.useJwt().adminService.fetchAdmins(query)

        const { data, pagination } = response.data;

        this.totalRecords = pagination.totalRecords
        this.perPage = pagination.limit;
        this.users = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
        this.showListTable = true;
      }
    },
    onRowClicked(rowItem){
      this.$router.push({ name: 'admin-single', params: { id: rowItem._id } })
      .catch(() => {})
    },
    getMFAStatus(item){
      return get(item, 'mfa_conf') && get(item, 'mfa_conf.is_active') ? "active" : "not configured";
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-select.scss';
  .width-100 {
    width: 100px;
  }
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  .loan-list-tb-row {
    cursor: pointer !important;
  }
</style>

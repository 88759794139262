<template>
  <b-overlay :show="loading" opacity="0.5">
    <list-table 
    :records="offering_types" 
    :columns="tableColumns"
    :total-records="totalRecords"
    :current-page-number.sync="currentPage"
    :search-filter.sync="searchFilter"
    :show-import-button="false"
    :show-export-button="false"
    @row-clicked="onRowClicked"
    >
    <template v-if="can('offering_types:CanAddNew')" #action_button>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="primary"
        :to="{ name: 'admin-new-offering-type' }"
      >
        <span class="align-middle text-lg">New Offering Type</span>
      </b-button>
    </template>

    <template #filters>
      <b-row>
        <b-col cols="12" md="2" class="">
          <v-select
            class="filter-select select-size-md"
            placeholder="Status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            :reduce="val => val.value"
            :disabled="loading"
            @input="(val) => statusFilter = val"
          />
        </b-col>

        <b-col cols="12" md="2" class="">
          <v-select
            class="filter-select select-size-md"
            placeholder="Church Level"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="churchLevelFilter"
            :options="churchLevelOptions"
            :reduce="val => val.value"
            :disabled="loading"
            @input="(val) => churchLevelFilter = val"
          />
        </b-col>
      </b-row>
    </template>

    <template #cell(supported_levels)="data">
      <span>
        {{ data.item.supported_levels.map(level => churchLevelOptions.find(opt => opt.value === level).label || 'N/A').join(", ") }}
      </span>
    </template>

    <template #cell(status)="data">
      <b-badge
        pill
        :variant="`light-${resolveStatusVariant(data.item.status)}`"
        class="text-capitalize"
      >
        {{ getUserStatusText(data.item.status) }}
      </b-badge>
    </template>

    <template #cell(actions)="data">
      <b-dropdown
        variant="link"
        no-caret
        :right="$store.state.appConfig.isRTL"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>

        <b-dropdown-item :to="{ name: 'admin-update-offering-type', params: { id: data.item._id } }">
          <feather-icon icon="FileTextIcon" />
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>

         <b-dropdown-item v-if="data.item.status === 'active'" @click="onDeleteOfferingType(data.item._id)">
          <feather-icon icon="Trash2Icon" class="text-danger" />
          <span class="align-middle ml-50 text-danger">Delete</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>
    </list-table>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BRow, BCol, BAlert, BLink, BTable,
  BMedia, BAvatar, BButton, BFormFile,
  BBadge, BDropdown, BDropdownItem, BPagination,
  BInputGroup, BInputGroupAppend, BFormInput
} from 'bootstrap-vue'
import { get, debounce } from "lodash"

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import ListTable from "@/@core/components/ListTable/ListTable.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const watchHandler = {
  handler(){
    this.currentPage = 1;
    this.debouncedFetchData()
  }, 
  immediate: false
}

const watchHandlerWithPageReset = {
  handler(){
    this.debouncedFetchData()
  }, 
  immediate: false
}
export default {
  components: {
    ListTable,

    BOverlay,
    BCard,
    BTable,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BLink,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BFormFile,
    BInputGroup, 
    BInputGroupAppend, 
    BFormInput,

    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      showFilter: false,
      showListTable: false,

      searchFilter: "",
      statusFilter: null,
      churchLevelFilter: null,

      offering_types: [],
      tableColumns: [
        { key: 'title', sortable: false },
        { key: 'supported_levels', sortable: false },
        { key: 'status', sortable: false },
        { key: 'created', label: 'Date', sortable: false, formatter: val => `${this.formatDate(val)}`, },
        { key: 'actions' },
      ],
      statusOptions: [
        { label: 'Active', value: 'active' },
        { label: 'Deleted', value: 'deleted' },
      ],
      debouncedFetchData: () => {}
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
    dataMeta(){
      const localItemsCount = this.users.length || 0;
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalRecords,
      }
    }
  },
  watch: {
    currentPage: watchHandler,
    perPage: watchHandlerWithPageReset,
    statusFilter: watchHandlerWithPageReset,
    searchFilter: watchHandlerWithPageReset,
    churchLevelFilter: watchHandlerWithPageReset,
  },
  created(){
    this.debouncedFetchData = debounce(this.fetchAllData, 500);

    const { 
      status = "active", 
      page = 1, 
      level = "",
      search = "", 
      limit = this.perPage 
    } = this.$route.query;

    this.currentPage = +page;
    this.searchFilter = search;
    this.statusFilter = status;
    this.churchLevelFilter = level;
    this.perPage = +limit;
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true; 

        const query = {
          limit: this.perPage,
          page: this.currentPage,
          status: this.statusFilter,
          search: this.searchFilter,
          level: this.churchLevelFilter
        }
        this.$router.push({ query }).catch(() => {});

        const response = await this.useJwt().adminService.fetchOfferingTypes(query)

        const { data, pagination } = response.data;

        this.offering_types = data;
        this.perPage = pagination.limit;
        this.totalRecords = pagination.totalRecords
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
        this.showListTable = true;
      }
    },
    async onDeleteOfferingType(id){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Update?',
          text: `Do you want to delete this offering type?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        await this.useJwt().adminService.deleteOfferingType(id);
        await this.fetchAllData();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `Offering type deleted successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.statusText") || error.message;

        this.$nextTick(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error_message
            },
          });
        })
      } finally {
        this.loading = false;
      }
    },
    onRowClicked(rowItem){
      this.$router.push({ name: 'admin-update-offering-type', params: { id: rowItem._id } })
      .catch(() => {})
    },
  }
}
</script>

<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-select.scss';
  .width-100 {
    width: 100px;
  }
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  .loan-list-tb-row {
    cursor: pointer !important;
  }
</style>

<template>
  <b-overlay rounded="sm" :show="loading">
    <AuthWrapper>
      <template #card>
        <div class="text-center">
          <div class="mb-3">
            <h1>Change Password</h1>
            <h5>Your current password has expired. Please provide a new unique password.</h5>
          </div>
        </div>
        <b-alert v-if="errorMessage.length" variant="danger" show>
          <div class="alert-body font-small-2">
            <b-card-text>
              <feather-icon icon="AlertCircleIcon" class="mr-1" />
              <small>{{ errorMessage }}</small>
            </b-card-text>
          </div>
        </b-alert>

        <validation-observer ref="simpleRules" #default="{ invalid }">
          <b-form class="my-2" @submit.prevent="changePassword">
            <!--                password -->
            <b-form-group label="New Password" label-for="reset-password-new">
              <validation-provider
                #default="{ errors }"
                name="Password"
                :rules="{
                  required: true,
                  password: true,
                }"
              >
                <div class="exxtra-input">
                  <span class="leading">
                    <PasswordLock />
                  </span>
                  <input
                    id="login-password"
                    v-model="password"
                    :type="password1FieldType"
                    name="reset-password-new"
                    placeholder="*****"
                  />
                  <span class="trailing" @click="togglePassword1Visibility">
                    <feather-icon :icon="password1ToggleIcon" />
                  </span>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!--                  Confirm password -->
            <b-form-group label="Confirm Password" label-for="confirm-password">
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <div class="exxtra-input">
                  <span class="leading">
                    <PasswordLock />
                  </span>
                  <input
                    id="reset-password-confirm"
                    v-model="cPassword"
                    :type="password2FieldType"
                    placeholder="*****"
                  />
                  <span class="trailing" @click="togglePassword2Visibility"> 
                    <feather-icon :icon="password2ToggleIcon" />
                  </span>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div>
              <b-button
                class="py-1"
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Change Password
              </b-button>
            </div>
            <p v-if="daysBeforePasswordExpire > 0" class="text-center mt-2">
              <b-link :to="{ name: 'admin-home' }">
                <feather-icon icon="ChevronLeftIcon" />
                Back to Home
              </b-link>
            </p>

            <p v-else class="text-center mt-2">
              <b-link @click="logout">
                <feather-icon icon="LogOutIcon" />
                Logout
              </b-link>
            </p>
          </b-form>
        </validation-observer>
      </template>
    </AuthWrapper>
  </b-overlay>
</template>

<script>
import BottomDesign from "@/assets/svg/BottomDesign.svg";
import PasswordLock from "@/assets/svg/PasswordLock.svg";
import TurtleRentLogo from "@core/layouts/components/Logo.vue";
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { required } from "@validations";
import { MUTATE_SETTINGS, MUTATE_USER_DATA } from "@/store/config/mutation-keys";

import {
  BAlert,
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BOverlay,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  components: {
    AuthWrapper,
    BottomDesign,
    PasswordLock,
    TurtleRentLogo,
    BOverlay,
    BAlert,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      cPassword: "",
      password: "",
      // validation
      required,

      // Toggle Password
      password1FieldType: "password",
      password2FieldType: "password",
      password3FieldType: "password",

      errorMessage: "",
    };
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    password2ToggleIcon() {
      return this.password2FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    daysBeforePasswordExpire() {
      return this.getValueFromSource(this.currentUser, "days_before_password_expire", 90)
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === "password" ? "text" : "password";
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === "password" ? "text" : "password";
    },
    async changePassword() {
      try {
        this.loading = true;

        const success = await this.$refs.simpleRules.validate();
        if (!success) {
          return;
        }

        const response = await this.useJwt().adminAuthService.changePassword({
          password: this.password,
        });

        const { access_token, user, settings } = response.data.data;

        // put data at the store before route

        this.$store.commit(`auth/${MUTATE_USER_DATA}`, user);
        this.$store.commit(`auth/${MUTATE_SETTINGS}`, settings);

        this.useJwt().authService.setToken(access_token);

        await this.$router.push({ name: "admin-home" }).catch(() => {});

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Password changed successfully.",
            icon: "EditIcon",
            variant: "success",
          },
        });
      } catch (error) {
        const error_message = this.getActualValueFromSource(error, "response.data.message") || error.message;
        console.log('error ', error);

        console.log('response data ', this.getActualValueFromSource(error, 'response.data.message'))
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>

<template>
<b-card class="rounded-lg">
  <div class="d-flex align-items-center justify-content-end">
    <div>
      <UserViewActionCard :user-data="userData" @update-user-data="$emit('update-user-data', $event)"></UserViewActionCard>
    </div>
  </div>
  <div class="card-body">
    <div v-if="getValueFromSource(userData, 'avatar.path') || getValueFromSource(userData, 'full_name').trim()">
      <div class="text-center">
        <b-avatar 
          ref="previewEl" 
          class="shadow-sm" :src="getValueFromSource(userData, 'avatar.path')" 
          :text="avatarText(`${getValueFromSource(userData, 'first_name')}
          ${getValueFromSource(userData, 'last_name')}`)" 
          :variant="`light-${resolveStatusVariant(getLoanStatusTextForClient(getValueFromSource(userData, 'status')))}`" 
          size="10rem" 
        />
      </div>
    </div>
    <div class="text-center my-1">
      <div class="d-flex align-items-center justify-content-center">
        <h2 class="font-weight-bold m-0 mr-1">
          {{ getValueFromSource(userData, 'full_name') }}
        </h2>

        <div>
          <b-badge pill
            :variant="`light-${resolveStatusVariant(getLoanStatusTextForClient(getValueFromSource(userData, 'status')))}`"
          >
            {{ getUserStatusText(getValueFromSource(userData, 'status')) }}
          </b-badge>
        </div>
      </div>
    </div>
    <div class="px-1">

      <!--                  email-->
      <div class="my-2">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h6 class="m-0 p-0">
              Email
            </h6>
            <p class="lead p-0 m-0">
              {{ getValueFromSource(userData, 'email') || '-' }}
            </p>
          </div>
          <div>
            <span v-if="getValueFromSource(userData, 'meta.email_verified')">
              <small>verified </small>
              <feather-icon class="text-success" icon="CheckCircleIcon" />
            </span>
          </div>
        </div>
      </div>

      <!--                  phone-->
      <div class="my-2">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h6 class="m-0 p-0">
              Phone
            </h6>
            <p class="lead p-0 m-0">
              {{ getValueFromSource(userData, 'phone') }}
            </p>
          </div>
          <div>
            <span v-if="getValueFromSource(userData, 'meta.phone_verified')">
              <small>verified </small>
              <feather-icon class="text-success" icon="CheckCircleIcon" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BBadge,
  BAlert,
  BDropdown,
  BDropdownItem,
  BDropdownGroup
} from 'bootstrap-vue'
import UserViewActionCard from "./UserViewActionCard.vue";

export default {
  components: {
    UserViewActionCard,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BAlert,
    BDropdown,
    BDropdownItem,
    BDropdownGroup
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {},
}
</script>

<style>

</style>

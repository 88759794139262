<template>
  <b-overlay :show="loading">
    <add-update-resource-action
        ref="newResourceActionForm"
        :updating-resource-action="resourceAction"
        :resource-id="resourceId"
        @update="onUpdateResourceAction"
    />
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AddUpdateResourceAction from "@/pages/admin/administrations/resource-actions/add-update-resource-action.vue";

import { get } from "lodash";
import { BOverlay } from "bootstrap-vue";

export default {
  name: "UpdateResourceAction",
  components: {
    AddUpdateResourceAction,
    BOverlay
  },
  data() {
    return {
      loading: false,
      resourceId: "",
      resourceAction: null,
      resourceActionId: ""
    }
  },
  created() {
    this.getResourceAction();
  },
  methods: {
    async getResourceAction() {
      try {
        this.loading = true

        // get values from params and save
        const {
          id,
          resource_id
        } = this.$route.params;

        this.resourceActionId = id;
        this.resourceId = resource_id;
        const { data } = await this.useJwt()
            .adminService
            .getResourceAction(id);
        this.resourceAction = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async onUpdateResourceAction(payload) {
      try {
        this.loading = false;

        await this.useJwt()
            .adminService
            .updateResourceAction(this.resourceActionId, payload);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `Resource Action updated successfully`
          },
        });
        await this.$router.push({
          name: "admin-update-resource",
          params: {
            id: this.resourceId
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>

</style>

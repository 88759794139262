<template>
  <b-overlay :show="loading">
    <b-alert
      variant="danger"
      :show="offeringTypeData === undefined && !loading"
    >
      <h4 class="alert-heading">
        Error fetching offering details
      </h4>
      <div class="alert-body">
        <b-link
          class="alert-link"
          :to="{ name: 'admin-offering-types'}"
        >
          Offering Types
        </b-link>
      </div>
    </b-alert>


    <add-or-update-offering-type-form 
      v-if="offeringTypeData" 
      :updating-offering-type="offeringTypeData" 
      @update="updateOfferingType" 
    />
  </b-overlay>
</template>

<script>
import { get } from 'lodash';
import { BOverlay, BAlert, BLink } from 'bootstrap-vue'

import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddOrUpdateOfferingTypeForm from '@core/components/shared/admin/offering-types/AddOrUpdateOfferingTypeForm.vue'

export default {
  components: {
    BLink,
    BAlert,
    vSelect,
    BOverlay,
    AddOrUpdateOfferingTypeForm,
  },
  data(){
    return {
      loading: false,
      errorMessage: '',

      offeringTypeData: null,
    }
  },
  created() {
    this.fetchOfferingType();
  },
  methods: {
    async fetchOfferingType(){
      try {
        this.loading = true;
        this.errorMessage = '';
        
        const { id } = this.$route.params
        const response = await this.useJwt().adminService.fetchOfferingType(id);

        const offeringTypeData = get(response, 'data.data');
        this.offeringTypeData = offeringTypeData;
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async updateOfferingType(payload){
      try {
        this.loading = true;
        this.errorMessage = '';
        
        const { id } = this.$route.params
        await this.useJwt().adminService.updateOfferingType(id, payload);
    
        await this.$router.push({
          name: "admin-offering-types"
        });

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'EditIcon',
            variant: 'success',
            text: `Offering type updated successfully`
          },
        });
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

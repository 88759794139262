<template>
  <b-overlay :show="loading">
    <b-alert
      variant="danger"
      :show="userData === undefined && !loading"
    >
      <h4 class="alert-heading">
        Error fetching User details
      </h4>
      <div class="alert-body">
        <b-link
          class="alert-link"
          :to="{ name: 'admin-users'}"
        >
          Users
        </b-link>
      </div>
    </b-alert>

    <template v-if="userData">
      <div>
       <b-row>
        <b-col cols="12" md="8">
          <UserViewUserInfoCard :user-data="userData" />
        </b-col>
       </b-row>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import { formatDate, formatMoney } from "@/@core/utils/utils"
import ListTable from "@core/components/ListTable/ListTable.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BAlert, BAvatar, BBadge, BImg, BCard, BCol, BLink, BOverlay, BRow, BCardImg } from "bootstrap-vue"

import { camelCase, get, omit, startCase } from "lodash";
import VueEasyLightbox from 'vue-easy-lightbox';
// import CustomFieldAnswers from "./CustomFieldAnswers.vue";
// import UserViewActionCard from "./UserViewActionCard.vue";
import UserViewUserInfoCard from "./UserViewUserInfoCard.vue";

export default {
  components: {
    ListTable,
    VueEasyLightbox,
    BCardImg,
    BOverlay,
    BAlert,
    BAvatar,
    BLink,
    BImg,
    BBadge,

    BRow,
    BCol,
    BCard,

    // components
    UserViewUserInfoCard,
    // UserViewActionCard,
    // CustomFieldAnswers,
  },
  directives: {},
  data(){
    return {
      loading: false,
      userData: null,

      loanData: [],

      frontImageLoading: true,
      backImageLoading: true,
      cardPreviewVisible: false,

      index: 0, // default: 0
      loanTableColumns: [
        { key: 'purpose', sortable: false },
        { key: 'status', sortable: false },
        { key: 'created', sortable: false, formatter: val => `${formatDate(val)}`, label: 'Date', },
        { key: 'amount', sortable: false, label: 'Amount', formatter: val => `GH¢ ${formatMoney(val)}` },
      ]
    }
  },
  async created() {
    await this.fetchUserDetails();
  },
  methods: {
    async fetchUserDetails(){
      try {
        this.loading = true;
        const { id } = this.$route.params;

        const response = await this.useJwt().adminService.fetchUser(id);
        this.userData = get(response, 'data.data')
      } catch (error){
        this.userData = undefined;

        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    getImageDataForPreview(image_data){
      const {
        path: src,
        name: title
      } = image_data
      return [{
        src,
        title
      }]
    },
    onImgLoad(path) {
      this[path] = false
    },
    showImg(index) {
      this.index = index
      this.cardPreviewVisible = true
    },
    handleCardPreviewVisibility() {
      this.cardPreviewVisible = false
    },
    onRowClicked(rowItem){
      this.$router.push({
        name: 'admin-loan-single',
        params: {
          id: rowItem._id
        } })
          .catch(() => {})
    },
    formatText(value) {
      return startCase(camelCase(value));
    },
    omitObject(object, path) {
      return omit(object, [path])
    }
  }
}
</script>

<style lang="scss">
small {
  font-size: 10px;
}

h4 {
  color: #459042 !important;
}
</style>

<template>
  <b-overlay :show="loading">
    <div>
      <h1 class="px-1">
        Users
      </h1>
      <b-row class="px-1 match-height">
        <b-col v-for="(stats, index) in userStats" :key="`stats-${index}`" md="4">
          <b-card>
             <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar
                    size="48"
                    variant="primary"
                  >
                    <feather-icon
                      size="24"
                      :icon="stats.icon"
                    />
                  </b-avatar>
                </b-media-aside>
  
                <b-media-body class="my-auto">
                  <b-card-text class="font-small-3 mb-0 text-right">
                    {{ stats.title }}
                  </b-card-text>
                  <h1 class="font-weight-bolder mb-0 text-right">
                    {{ stats.subtitle }}
                  </h1>
                </b-media-body>
              </b-media>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <div>
      <h1 class="px-1 my-2">
        Offerings
      </h1>
      <b-row class="px-1 match-height">
        <b-col v-for="(stats, index) in offeringStats" :key="`stats-${index}`" md="4">
          <b-card>
             <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar
                    size="48"
                    variant="primary"
                  >
                    <feather-icon
                      size="24"
                      :icon="stats.icon"
                    />
                  </b-avatar>
                </b-media-aside>
  
                <b-media-body class="my-auto">
                  <b-card-text class="font-small-3 mb-0 text-right">
                    {{ stats.title }}
                  </b-card-text>
                  <h1 class="font-weight-bolder mb-0 text-right">
                    {{ stats.subtitle }}
                  </h1>
                </b-media-body>
              </b-media>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <div class="mt-3">
          <admin-offerings-chart />
        </div>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { get } from "lodash"
import { 
  BOverlay,
  BRow,
  BCol,
  BAlert,
  BLink,
  BButton,
  BImg,
  BCardSubTitle,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody, 
} from 'bootstrap-vue'

import AdminOfferingsChart from '@/@core/components/shared/admin/AdminOfferingsChart.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    BImg,
    BCardSubTitle,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody, 
    AdminOfferingsChart
  },
  data() {
    return {
      rawDashboardStats: {
        employees: 0,
        pending_employees: 0,
        active_reference_statements: 0,
        pending_reference_statements: 0,
        employers: 0,
        pending_employers: 0,
      },
      loading: false
    }
  },
  computed: {
    userStats() {
      return [
      {
          icon: 'UsersIcon',
          color: 'light-primary',
          title: 'No. of Users',
          subtitle: get(this.rawDashboardStats, 'total_users_count', 0),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UsersIcon',
          color: 'light-primary',
          title: 'Total Members',
          subtitle: get(this.rawDashboardStats, 'total_members_count', 0),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UsersIcon',
          color: 'light-primary',
          title: 'Total Churches',
          subtitle: get(this.rawDashboardStats, 'total_churches_count', 0),
          customClass: 'mb-2 mb-xl-0',
        },
      ]
    },
    offeringStats() {
      return [
      {
          icon: 'ArchiveIcon',
          color: 'light-primary',
          title: 'Number of offerings',
          subtitle: get(this.rawDashboardStats, 'number_of_offerings', 0),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ArchiveIcon',
          color: 'light-primary',
          title: 'Total Amount',
          subtitle: get(this.rawDashboardStats, 'total_offering_amount', 0),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ArchiveIcon',
          color: 'light-primary',
          title: 'Highest Amount',
          subtitle: get(this.rawDashboardStats, 'hightest_amount', 0),
          customClass: 'mb-2 mb-xl-0',
        },
      ]
    },
    
  },
  created() {
    this.fetchDashboardStats()
  },
  methods: {
    async fetchDashboardStats() {
      try {
        this.loading = true; 

        const request = await this.useJwt().adminService.fetchDashboardStatsData({});
        const { data } = request.data;
        this.rawDashboardStats = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        // console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
  @import '@core/scss/vue/libs/chart-apex.scss';
</style>

<template>
  <b-overlay :show="loading" opacity="0.5">
    <div>
      <list-table
        :records="settlements" 
        :columns="tableColumns"
        :total-records="totalRecords"
        :current-page-number.sync="currentPage"
        :show-export-button="true"
        :show-search="false"
        @export="onExportSettlement"
      >
        <template #filters>
          <b-row>
            <b-col cols="12" md="2" class="">
              <v-select
                class="filter-select select-size-md"
                placeholder="Status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="statusFilter"
                :options="statusOptions"
                :reduce="val => val.value"
                :disabled="loading"
                @input="(val) => statusFilter = val"
              />
            </b-col>

            <b-col cols="12" md="3">
              <b-form-datepicker
                v-model="dateFrom"
                :close-button="true"
                reset-button
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                placeholder="From"
                class="mr-2"
              />
            </b-col>

            <b-col cols="12" md="3" class="mb-md-0 mb-2">
              <b-form-datepicker
                v-model="dateTo"
                placeholder="To"
                reset-button
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                :min="dateFrom"
              />
            </b-col>
          </b-row>
        </template>

        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <template #cell(member)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="getValueFromSource(data, 'item.member.avatar.path')"
                :text="sentenceCase(avatarText(`${data.item.member.full_name}`))"
                :variant="`light-${resolveUserRoleVariant(data.item.member.user_type)}`"
                :to="{ name: 'admin-user-single', params: { id: data.item.member._id } }"
              />
            </template>
            <b-link
              :to="{ name: 'admin-user-single', params: { id: data.item.member._id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ sentenceCase(getValueFromSource(data, 'item.member.full_name')) }}
            </b-link>
            <span v-if="data.item.email" class="text-muted">{{ data.item.member.email }}</span><br />
            <span v-if="data.item.phone" class="text-muted">{{ data.item.member.phone }}</span>
          </b-media>
        </template>

        <template #cell(offering_type)="data">
          <span>
            {{ data.item.offering_type.title }}
          </span>
        </template>

        <template #cell(meta)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(getValueFromSource(data.item, 'meta.church_approval'))}`"
            class="text-capitalize"
          >
            {{ getUserStatusText(getValueFromSource(data.item, 'meta.church_approval')) }}
          </b-badge>
        </template>
      </list-table>
    </div>
  </b-overlay>
</template>

<script>
import {
  BMedia, BAvatar, BButton, BFormFile,
  BBadge, BDropdown, BDropdownItem, BPagination,
  BOverlay, BCard, BRow, BCol, BAlert, BLink, BTable,
  BInputGroup, BInputGroupAppend, BFormInput, BFormDatepicker
} from 'bootstrap-vue'

import { get, debounce } from "lodash"

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import FileDownload from "js-file-download";
import ListTable from "@/@core/components/ListTable/ListTable.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const watchHandler = {
  handler(){
    this.debouncedFetchData()
  }, 
  immediate: false
}

const watchHandlerRequiresPageReset = {
  handler(){
    this.currentPage = 1
    this.debouncedFetchData()
  }, 
  immediate: false
} 

export default {
  components: {
    ListTable,

    BOverlay,
    BCard,
    BTable,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BLink,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BFormFile,
    BFormInput,
    BInputGroup, 
    BFormDatepicker,
    BInputGroupAppend, 

    vSelect,
  },
  directives: {
    Ripple,
  },
  data(){
    return {
      loading: false,
      showFilter: false,
      showListTable: false,
      
      statusFilter: 'success',
      dateFrom: null,
      dateTo: null,

      settlements: [],

      tableColumns: [
        { key: 'id', sortable: false },
        { key: 'domain', sortable: false },
        { key: 'status', sortable: false },
        { key: 'currency', sortable: false },
        { key: 'total_amount', sortable: false, formatter: val => `GH₵ ${this.formatMoney(val / 100)}` },
        { key: 'total_processed', sortable: false, formatter: val => `GH₵ ${this.formatMoney(val / 100)}` },
        { key: 'total_fees', sortable: false, formatter: val => `GH₵ ${this.formatMoney(val)}` },
        { key: 'settlement_date', label: 'Settlement Date', sortable: false, formatter: val => `${this.formatDate(val)}`, },
        { key: 'subaccount.business_name', label: 'Church', sortable: false },
        { key: 'subaccount.settlement_bank', label: 'Bank', sortable: false },
        { key: 'subaccount.account_number', label: 'Account Number', sortable: false },
      ],

      statusOptions: [
        { label: 'Success', value: 'success' },
        { label: 'Processing', value: 'processing' },
        { label: 'Pending', value: 'pending' },
        { label: 'Failed', value: 'failed' },
      ],

      debouncedFetchData: () => {}
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
    dataMeta(){
      const localItemsCount = this.users.length || 0;
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalRecords,
      }
    }
  },
  watch: {
    perPage: {
      handler(){
        this.currentPage = 1;
        this.debouncedFetchData()
      }, 
      immediate: false
    },
    currentPage: watchHandler,
    statusFilter: watchHandlerRequiresPageReset,
    dateFrom: watchHandlerRequiresPageReset,
    dateTo: watchHandlerRequiresPageReset,
  },
  created(){
    this.debouncedFetchData = debounce(this.fetchAllData, 500);

    const { 
      page = 1, 
      status = "success", 
      from = "",
      to = "",
      limit = this.perPage 
    } = this.$route.query;

    this.currentPage = +page;
    this.statusFilter = status;
    this.dateFrom = from;
    this.dateTo = to;
    this.perPage = +limit;
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true; 

        const query = {
          limit: this.perPage,
          page: this.currentPage,
          status: this.statusFilter,
          from: this.dateFrom,
          to: this.dateTo
        }

        this.$router.push({ query }).catch(() => {});

        const request = await this.useJwt().adminService.fetchSettlements(query);
        const { data, pagination } = request.data;

        this.totalRecords = pagination.totalRecords
        this.perPage = pagination.limit;
        this.settlements = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
        this.showListTable = true;
      }
    },
    async onExportSettlement(){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Export?',
          text: "This will export data based on current filters.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        const query = {
          search: this.statusFilter,
          from: this.dateFrom,
          to: this.dateTo
        }

        const response = await this.useJwt().adminService.exportSettlement(query);
        FileDownload(response.data, "settlements.csv");
      } catch (error) {
        // const error_message = get(error, "response.statusText") || error.message;
        this.$nextTick(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'There is no data to export'
            },
          });
        })
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-select.scss';
  .width-100 {
    width: 100px;
  }
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  .loan-list-tb-row {
    cursor: pointer !important;
  }
</style>

<template>
  <b-overlay :show="loading">
    <b-alert
      variant="danger"
      :show="churchData === undefined && !loading"
    >
      <h4 class="alert-heading">
        Error fetching Church details
      </h4>
      <div class="alert-body">
        <b-link
          class="alert-link"
          :to="{ name: 'admin-churches'}"
        >
          Churches
        </b-link>
      </div>
    </b-alert>

    <template v-if="churchData">
      <validation-observer ref="newUserForm" #default="{invalid}">
        <b-form ref="form" @submit.prevent="onUpdateChurchData">
          <b-row>
            <b-col cols="12" xl="8" md="8">
              <div>
                <b-card>
                  <b-row class="">
                    <b-col
                      cols="11"
                    >
                      <div class="d-flex align-items-center">
                        <feather-icon
                          icon="UserIcon"
                          size="19"
                        />
                        <h4 class="mb-0 ml-50">
                          Personal Information
                        </h4>
                      </div>
                    </b-col>

                    <b-col
                      cols="1"
                    >
                      <div v-if="canDeleteMFA && churchData.mfa_conf"
                        class="justify-content-end"
                      >
                        <b-dropdown variant="link" class="p-0" no-caret :right="$store.state.appConfig.isRTL">
                          <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-top text-body" />
                          </template>
                          <b-dropdown-item @click="onResetUserMFA">
                            <feather-icon class="text-danger" icon="EditIcon" />
                            <span class="align-middle text-danger ml-50">Reset MFA</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </b-col>
                  </b-row>

                  <!-- Media -->
                  <b-media class="mt-3 mb-3">
                    <template #aside>
                      <b-avatar
                        ref="previewEl"
                        :src="avatarPath"
                        :text="avatarText(`${churchData.first_name} ${churchData.last_name}`)"
                        :variant="`light-${resolveStatusVariant(getLoanStatusTextForClient(getValueFromSource(churchData, 'status')))}`"
                        size="6rem"
                      />
                    </template>

                    <h4 class="mb-1">
                      Avatar
                    </h4>

                    <div v-if="can('users:CanUpdate')" class="d-flex flex-wrap">
                      <b-button
                        variant="primary"
                        :disabled="!hasBasicUpdatePermission"
                        @click="$refs.avatarElement.$el.childNodes[0].click()"
                      >
                        <b-form-file
                          v-show="false"
                          ref="avatarElement"
                          accept="image/*"
                          placeholder=""
                          no-drop
                          @input="onImageRenderer"
                        />
                        <span class="d-none d-sm-inline">Change Avatar</span>
                        <feather-icon
                          icon="EditIcon"
                          class="d-inline d-sm-none"
                        />
                      </b-button>

                      <b-button
                        v-if="getValueFromSource(churchData, 'avatar.path')"
                        :disabled="!hasBasicUpdatePermission"
                        variant="outline-secondary"
                        class="ml-1"
                        @click="onRemoveAvatar"
                      >
                        <span class="d-none d-sm-inline">Remove</span>
                        <feather-icon
                          icon="TrashIcon"
                          class="d-inline d-sm-none"
                        />
                      </b-button>
                    </div>
                  </b-media>

                  <b-row class="">
                    <b-col cols="12" md="6">
                      <b-form-group label="First Name" label-for="first_name">
                        <template #label>
                          <span>First Name<span style="color: tomato">*</span></span>
                        </template>

                        <validation-provider #default="{ errors }" name="First Name" rules="required">
                          <div class="custom-input">
                            <span class="leading">
                              <feather-icon icon="UserIcon" size="20" />
                            </span>
                            <input id="first_name" v-model="churchData.first_name" placeholder="Enter your First Name Here" :disabled="!hasBasicUpdatePermission">
                          </div>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" md="6">
                      <b-form-group label="Last Name" label-for="last_name">
                        <template #label>
                          <span>Last Name<span style="color: tomato">*</span></span>
                        </template>

                        <validation-provider #default="{ errors }" name="Last Name" rules="required">
                          <div class="custom-input">
                            <span class="leading">
                              <feather-icon icon="UserIcon" size="20" />
                            </span>
                            <input id="last_name" v-model="churchData.last_name" placeholder="Enter your Last Name Here" height="20px" :disabled="!hasBasicUpdatePermission">
                          </div>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row class="">
                    <b-col cols="12" md="6">
                      <b-form-group
                        label="Email"
                        label-for="email"
                      >
                        <b-form-input
                          id="email"
                          v-model="churchData.email"
                          type="email"
                          :disabled="!hasBasicUpdatePermission"
                        />
                      </b-form-group>

                      <b-form-group label="Gender" label-for="gender" class="mt-2">
                        <validation-provider #default="{ errors }" name="gender" rules="required">
                          <v-select
                            id="gender"
                            v-model="churchData.gender"
                            :loading="loading"             
                            :clearable="false"
                            :options="genderOptions"
                            :reduce="val => val.value"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :disabled="!hasBasicUpdatePermission"
                          >
                            <span slot="no-options">Select gender</span>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    
                    <b-col cols="12" md="6">
                      <b-form-group
                        label="Phone"
                        label-for="phone"
                      >
                        <b-input-group class="input-group-merge">
                          <!-- <b-input-group-prepend is-text>
                            <span :class="{
                              'text-success': getValueFromSource(churchData, 'meta.phone_verified'),
                              'text-warning': !getValueFromSource(churchData, 'meta.phone_verified')
                              }"
                            >
                              {{ getValueFromSource(churchData, 'meta.phone_verified') ? 'Verified' : 'Pending Verification' }}
                            </span>
                          </b-input-group-prepend> -->

                          <b-form-input
                            id="phone"
                            v-model="churchData.phone"
                            :disabled="!hasBasicUpdatePermission"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row class="mb-2 mt-5">
                    <b-col cols="12" md="6">
                      <h3 class="">
                        Church Information
                      </h3>

                      <b-form-group label="Church Level" label-for="church_level">
                        <template #label>
                          <span>Church Level<span style="color: tomato">*</span></span>
                        </template>
    
                        <validation-provider #default="{ errors }" name="church_level" rules="required">
                          <p>Select the church level to receive payments.</p>
                          <v-select
                            id="church_level"
                            v-model="churchData.church_profile.church_level"
                            :loading="loading"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="churchLevelOptions"
                            :reduce="val => val.value"
                            :clearable="false"
                            :disabled="!hasBasicUpdatePermission"
                          >
                            <span slot="no-options">Select church level</span>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
    
                      <b-form-group v-if="['assembly', 'district', 'area'].includes(churchData.church_profile.church_level)" label="Region" label-for="region">
                        <template #label>
                          <span>Region<span style="color: tomato">*</span></span>
                        </template>
    
                        <validation-provider #default="{ errors }" name="region" rules="required">
                          <v-select
                            id="region"
                            v-model="churchData.church_profile.church_region"
                            :loading="loading"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="churchRegionOptions"
                            :reduce="val => val.value"
                            :clearable="false"
                            :disabled="!hasBasicUpdatePermission"
                          >
                            <span slot="no-options">Select church region</span>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
    
                      <b-form-group v-if="['assembly', 'district', 'area'].includes(churchData.church_profile.church_level)" label="Area" label-for="area">
                        <template #label>
                          <span>Area<span style="color: tomato">*</span></span>
                        </template>
    
                        <validation-provider #default="{ errors }" name="area" rules="required">
                          <v-select
                            id="area"
                            v-model="churchData.church_profile.church_area"
                            :loading="loading"             
                            :clearable="false"
                            :options="churchAreaOptions"
                            :reduce="val => val.value"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :disabled="!hasBasicUpdatePermission"
                          >
                            <span slot="no-options">{{ !!churchData.church_profile.church_region ? 'Select church area' : 'Select church region first' }}</span>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
    
                      <b-form-group v-if="['assembly', 'district'].includes(churchData.church_profile.church_level)" label="District" label-for="region">
                        <template #label>
                          <span>District<span style="color: tomato">*</span></span>
                        </template>
    
                        <validation-provider #default="{ errors }" name="district" rules="required">
                          <v-select
                            id="district"
                            v-model="churchData.church_profile.church_district"
                            :loading="loading"             
                            :clearable="false"
                            :options="churchDistrictOptions"
                            :reduce="val => val.value"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :disabled="!hasBasicUpdatePermission"
                          >
                            <span slot="no-options">{{ !!churchData.church_profile.church_area ? 'Select church district' : 'Select church area first' }}</span>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
    
                      <b-form-group v-if="churchData.church_profile.church_level === 'assembly'" label="Assembly" label-for="assembly">
                        <template #label>
                          <span>Assembly<span style="color: tomato">*</span></span>
                        </template>
    
                        <validation-provider #default="{ errors }" name="assembly" rules="required">
                          <v-select
                            id="assembly"
                            v-model="churchData.church_profile.church_assembly"
                            :loading="loading"             
                            :clearable="false"
                            :options="churchAssemblyOptions"
                            :reduce="val => val.value"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :disabled="!hasBasicUpdatePermission"
                          >
                            <span slot="no-options">{{ !!churchData.church_profile.church_district ? 'Select church assembly' : 'Select church district first' }}</span>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" md="6">
                      <h3 class="">
                        Bank Information
                      </h3>

                      <b-form-group label="Bank Country" label-for="bank_level">
                        <template #label>
                          <span>Bank Country<span style="color: tomato">*</span></span>
                        </template>
    
                        <validation-provider #default="{ errors }" name="bank_country" rules="required">
                          <p>Select the country where the bank account is located at.</p>
                          <v-select
                            id="bank_country"
                            v-model="churchData.church_profile.bank_country"
                            :loading="loading"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="countryOptions"
                            :reduce="val => val.value"
                            :clearable="false"
                            :disabled="!hasBasicUpdatePermission"
                          >
                            <span slot="no-options">Select bank country</span>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
    
                      <b-form-group label="Bank Name" label-for="bank_name">
                        <template #label>
                          <span>Bank Name<span style="color: tomato">*</span></span>
                        </template>
    
                        <validation-provider #default="{ errors }" name="bank_name" rules="required">
                          <v-select
                            id="bank_name"
                            v-model="churchData.church_profile.bank_code"
                            :loading="loading"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="bankOptions"
                            :reduce="val => val.value"
                            :clearable="false"
                            :disabled="!hasBasicUpdatePermission"
                          >
                            <span slot="no-options">{{ !!churchData.church_profile.bank_country ? 'Select bank name' : 'Select bank country first' }}</span>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group
                        label="Account / Mobile Money Number"
                        label-for="bank_account_number"
                      >
                        <template #label>
                          <span>Account / Mobile Money Number<span style="color: tomato">*</span></span>
                        </template>

                        <b-form-input
                          id="bank_account_number"
                          v-model="churchData.church_profile.bank_account_number"
                          :disabled="!hasBasicUpdatePermission"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>

                 <b-button
                  class="mb-5"
                  style="width: 200px"
                  variant="primary"
                  block
                  type="submit"
                  :disabled="invalid || disableForm || loading"
                >
                  Update {{ capitalizeFirstWord(getValueFromSource(churchData, 'user_type')) }}
                </b-button>
              </div>
            </b-col>

            <b-col cols="12" xl="4" md="4">
              <b-card>
                <h4 class="">
                  Church Approval
                </h4>

                <b-badge
                  pill
                  :variant="`light-${resolveStatusVariant(getValueFromSource(churchData, 'church_profile.status'))}`"
                  class="text-capitalize mb-5"
                >
                  {{ getUserStatusText(getValueFromSource(churchData, 'church_profile.status')) }}
                </b-badge>
                <p>Only approved churches are available for members to select give. Declined churches will not be able to login to their account.</p>

                <div class="d-flex justify-content-between align-items-center flex-wrap">
                  <b-button
                    block
                    :disabled="loading || !can('users:CanApprove') || getValueFromSource(churchData, 'church_profile.status') === 'approved'"
                    style="width: 200px"
                    variant="primary"
                    @click="onApproveChurch"
                  >
                    Approve
                  </b-button>
  
                  <b-button
                    block
                    :disabled="loading || !can('users:CanDecline') || getValueFromSource(churchData, 'church_profile.status') === 'declined'"
                    style="width: 200px"
                    variant="danger"
                    @click="onDeclineChurch"
                  >
                    Decline
                  </b-button>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BAlert, BLink, BRow, BCol,
  BCard, BCardBody, BButton, BFormGroup, 
  BForm, BFormInput, BMedia, BAvatar,
  BFormFile, BFormDatepicker, BBadge,
  BInputGroup, BInputGroupPrepend, BDropdown,
  BDropdownItem, BFormSelect
} from "bootstrap-vue"
import Cleave from 'vue-cleave-component'

import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

import { get } from "lodash";
import { required, email } from '@validations'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';


export default {
  components: {
    Cleave,
    vSelect,
    flatPickr,
    BOverlay,
    BAlert,
    BLink,
    BRow,
    BCol,
    BCard, 
    BCardBody, 
    BButton,
    BFormGroup, 
    BForm, 
    BFormInput,
    BMedia, 
    BAvatar,
    BFormFile,
    BFormDatepicker,
    BBadge,
    BInputGroup,
    BDropdown,
    BFormSelect,
    BDropdownItem,
    BInputGroupPrepend,
    
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {},
  data(){
    return {
      loading: false,

      churchData: null,

      loadCounter: 0,

      genderOptions: [
        {
          value: "female",
          label: "Female"
        },
        {
          value: "male",
          label: "Male"
        }
      ],
      bankOptions: [],
      countryOptions: [],
      churchAreaOptions: [],
      churchRegionOptions: [],
      churchDistrictOptions: [],
      churchAssemblyOptions: [],

      avatarPath: "",
      updatedImage: null,
      cleaveDateOptions: {
        date: true,
        delimiter: '/',
        datePattern: ['Y', 'm', 'd'],
        blocks: [4, 2, 2],
        delimiters: ['-', '-', '-']
      },
      required,
      email
    }
  },
  computed: {
    hasBasicUpdatePermission(){
      return this.can('users:CanUpdate')
    },
    disableForm(){
      return !this.hasBasicUpdatePermission
    },
    canDeleteMFA() {
      return this.can("mfa_configuration:CanDelete");
    },
  },
  watch: {
    "churchData.church_profile.church_region": {
      handler(changed){
        if (changed && this.churchData.church_profile && this.churchData.church_profile.church_level !== "hq"){
          this.fetchChurchAreas();
        }
      },
      immediate: false
    },
    'churchData.church_profile.church_area': {
      handler(changed){
        if (changed && this.churchData.church_profile && ['area', 'district', 'assembly'].includes(this.churchData.church_profile.church_level)){
          this.fetchChurchDistrict();
        }
      },
      immediate: false
    },
    'churchData.church_profile.church_district': {
      handler(changed){
        if (changed && this.churchData.church_profile && ['area', 'district', 'assembly'].includes(this.churchData.church_profile.church_level)){
          this.fetchChurchAssemblies();
        }
      },
      immediate: false
    },
    'churchData.church_profile.bank_country': {
      handler(changed){
        if (changed){
          this.fetchBanks();
        }
      },
      immediate: false
    },
  },
  created(){
    this.fetchChurchDetails();

    this.fetchCountries();
    this.fetchChurchRegions();
  },
  methods: {
    async fetchCountries() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchCountries({ criteria: 'all' });
        const { data } = request.data;
        this.countryOptions = data.map(country => ({
          label: country.country,
          value: country.country.toLowerCase()
        }));

        // this.bank_name = '';
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchRegions() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchChurchRegions();
        const { data } = request.data;
        this.churchRegionOptions = data.map(opt => ({
          label: opt.name,
          value: opt.name
        }));

        this.loadCounter += 1;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchAreas() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchChurchAreas(this.churchData.church_profile.church_region);
        const { data } = request.data;

        this.churchAreaOptions = data.map(opt => ({
          label: opt.name,
          value: opt.name
        }));

        this.loadCounter += 1;

        if (this.churchData.church_profile){
          if (this.churchData.church_profile.church_level === "area" && this.loadCounter > 3){
            this.churchData.church_profile.church_area = '';
          }

          if (this.churchData.church_profile.church_level === "district" && this.loadCounter > 4){
            this.churchData.church_profile.church_area = '';
            this.churchData.church_profile.church_district = '';
          }

          if (this.churchData.church_profile.church_level === "assembly" && this.loadCounter > 4){
            this.churchData.church_profile.church_area = '';
            this.churchData.church_profile.church_district = '';
            this.churchData.church_profile.church_assembly = '';
          }
        }
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchDistrict() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchChurchDistricts(this.churchData.church_profile.church_region, this.churchData.church_profile.church_area);
        const { data } = request.data;
        this.churchDistrictOptions = data.map(opt => ({
          label: opt.name,
          value: opt.name
        }));

        this.loadCounter += 1;

        if (this.churchData.church_profile){
          if (this.churchData.church_profile.church_level === "district" && this.loadCounter > 4){
            this.churchData.church_profile.church_district = '';
          }

          if (this.churchData.church_profile.church_level === "assembly" && this.loadCounter > 4){
            this.churchData.church_profile.church_district = '';
            this.churchData.church_profile.church_assembly = '';
          }
        }
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchAssemblies() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchChurchAssemblies(this.churchData.church_profile.church_region, this.churchData.church_profile.church_area, this.churchData.church_profile.church_district);
        const { data } = request.data;
        this.churchAssemblyOptions = data.map(opt => ({
          label: opt.name,
          value: opt.name
        }));

        this.loadCounter += 1;

        if (this.churchData.church_profile){
          if (this.churchData.church_profile.church_level === "assembly" && this.loadCounter > 4){
            this.churchData.church_profile.church_assembly = '';
          }
        }
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchBanks() {
      try {
        this.loading = true;

        if (!this.churchData.church_profile.bank_country){
          this.bankOptions = [];
          return;
        }

        const query = {
          country: this.churchData.church_profile.bank_country
        }

        const request = await this.useJwt().sharedService.fetchBanks(query);
        const { data } = request.data;
        this.bankOptions = data.map(opt => ({
          label: opt.name,
          value: opt.code
        }));
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchDetails(){
      try {
        this.loading = true;
        const { id } = this.$route.params;
        
        const response = await this.useJwt().adminService.fetchUser(id);
        const churchData = get(response, 'data.data');
        
        this.churchData = churchData
        this.avatarPath = this.getValueFromSource(this.churchData, 'avatar.path', '');

        this.fetchBanks();
      } catch (error){
        this.churchData = undefined;

        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async onUpdateChurchData(){
      try {
        this.loading = true;

        const formData = new FormData();
        if (this.hasBasicUpdatePermission){
          formData.append('first_name', this.getValueFromSource(this.churchData, 'first_name', ''));
          formData.append('last_name', this.getValueFromSource(this.churchData, 'last_name', ''));
          formData.append('email', this.getValueFromSource(this.churchData, 'email', ''));
          formData.append('phone', this.getValueFromSource(this.churchData, 'phone', ''));
          formData.append('gender', this.getValueFromSource(this.churchData, 'gender', ''));


          formData.append('church_level', this.getValueFromSource(this.churchData.church_profile, 'church_level', ''));
          formData.append('church_region', this.getValueFromSource(this.churchData.church_profile, 'church_region', ''));
          formData.append('church_area', this.getValueFromSource(this.churchData.church_profile, 'church_area', ''));
          formData.append('church_district', this.getValueFromSource(this.churchData.church_profile, 'church_district', ''));
          formData.append('church_assembly', this.getValueFromSource(this.churchData.church_profile, 'church_assembly', ''));
          
          formData.append('bank_country', this.getValueFromSource(this.churchData.church_profile, 'bank_country', ''));
          formData.append('bank_code', this.getValueFromSource(this.churchData.church_profile, 'bank_code', ''));
          formData.append('bank_account_number', this.getValueFromSource(this.churchData.church_profile, 'bank_account_number', ''));
        }


        if (this.updatedImage){
          formData.append("avatar", this.updatedImage);
        }

        if (!this.hasBasicUpdatePermission){
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: "An unexpected error occurred. You might not have permission to update record."
            },
          });
          return false;
        }

        const church_profile_id = this.churchData.church_profile._id;
        await this.useJwt().adminService.updateChurch(church_profile_id, formData);
        await this.fetchChurchDetails();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'EditIcon',
            variant: 'success',
            text: `Church updated successfully.`
          },
        });
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async onRemoveAvatar(){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Avatar Removal?',
          text: "Are you sure you want to remove this avatar?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, remove it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (!result.value) {
          return;
        }

        const user_id = this.churchData._id;
        if (!user_id){
          return;
        }

        await this.useJwt().adminService.deleteUserAvatar(user_id);
        this.avatarPath = "";
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async onResetUserMFA() {
      try {
        this.loading = true;
        const result = await this.$swal({
          title: 'Reset User MFA Config',
          text: "Are you sure you want to reset user mfa configuration?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });
        if (!result.value || !this.churchData._id) {
          return;
        }
        await this.useJwt().adminService.resetUserMFA(this.churchData._id);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: "MFA on Admin User account has been reset successfully."
          }
        });
        await this.$router.push({
          name: "admin-users"
        })
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async onApproveChurch() {
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Account Approval?',
          text: "Are you sure you want to approve this church?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, approve!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        await this.useJwt().adminService.approveChurch(this.churchData.church_profile.id);
        await this.fetchChurchDetails();
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async onDeclineChurch() {
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm?',
          text: "Are you sure you want to decline this church?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, decline!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        await this.useJwt().adminService.declineChurch(this.churchData.church_profile.id);
        await this.fetchChurchDetails();
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    onImageRenderer(file) {
     const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          this.avatarPath = reader.result
          this.updatedImage = file;
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.custom-input {
  position: relative;
  display: block;
  color:  #80A0AF !important;

  .leading{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    padding-left: 12px;
    padding-right: 0.5rem;
    display: flex;
    align-items: center;
  }

  .trailing {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding-right: 12px;
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
  }

  .otp {
    letter-spacing: 2rem;
    font-weight: bold;
    text-align: center !important;
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
    padding-left: 3.5rem !important;
  }


  input, textarea, select {
    border: 1px solid $neutral;
    border-radius: 5px;
    width: 100%;
    top: 50%;
    bottom: 50%;
    min-width: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 2.75rem;
    color:  #80A0AF !important;

    &:focus {
      outline: none;
      border: 1px solid $main-color;
    }

    &::placeholder {
      color: $neutral;
      opacity: 0.3;
    }
    &:invalid {
      border-color: red;
    }
    &:required {
      border-color: red;
    }
    &:-ms-input-placeholder {
      color: $neutral;
    }
  }
}
</style>

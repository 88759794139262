<template>
  <b-overlay style="z-index: 10002" rounded="sm" :show="loading">

    <AuthWrapper>
      <template #heading>
        <SecurityLogo />
      </template>

      <template #card>
        <div class="text-center">
          <div>
            <h1> Two-Factor Authentication (2FA) </h1>
          </div>
          <div>
            <p>Choose a multi factor authentication method</p>
          </div>
        </div>
        <div class="">

          <div id="sms" class="mfa-method mb-1" @click="selectMFAMethod('sms')" @mouseover="toggleCards('sms')">
            <div class="d-flex align-items-center">
              <div class="mr-1">
                <TextMessageIcon />
              </div>
              <div>
                <div>
                  <h5>Text Message</h5>
                </div>
                <div>
                  <p>We will send a code to <span>{{ getUserMaskedPhoneNumber }}</span></p>
                </div>
              </div>
            </div>
          </div>

          <div id="email" class="mfa-method shadow mb-1" @click="selectMFAMethod('email')" @mouseover="toggleCards('email')">
            <div class="d-flex align-items-center">
              <div class="mr-1">
                <EmailIcon />
              </div>
              <div>
                <div>
                  <h5>Email</h5>
                </div>
                <div>
                  <p>We will send a code to <span>{{ getMaskedEmail }}</span></p>
                </div>
              </div>
            </div>
          </div>

          <div id="auth-app" class="mfa-method" @click="selectMFAMethod('app')" @mouseover="toggleCards('auth-app')">
            <div class="d-flex align-items-center">
              <div class="mr-1">
                <AuthenticatorAppIcon />
              </div>
              <div>
                <div>
                  <h5>Google Authenticator App</h5>
                </div>
                <div>
                  <p>Scan using an authenticator app</p>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <p class="text-center text-sm cursor-pointer" @click="logout">
              <feather-icon icon="LogOutIcon" />
              Logout
            </p>
          </div>
        </div>
      </template>

    </AuthWrapper>

  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BImg,
  BForm,
  BLink,
  BAlert,
  BButton,
  BOverlay,
  BCardText,
  BFormGroup,
  BCardTitle,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend
} from "bootstrap-vue";
import { get } from "lodash";
import { isEmpty } from "lodash/lang";
import EmailIcon from "@/assets/svg/emailIcon.svg";
import BottomDesign from "@/assets/svg/BottomDesign.svg";
import TextMessageIcon from "@/assets/svg/TextMessageIcon.svg";
import { required } from "@core/utils/validations/validations";
import TurtleRentLogo from "@core/layouts/components/Logo.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";
import AuthenticatorAppIcon from "@/assets/svg/AuthenticationAppIcon.svg";
import SecurityLogo from "@core/components/shared/auth/SecurityLogo.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    SecurityLogo,
    AuthWrapper,
    AuthenticatorAppIcon,
    EmailIcon,
    BottomDesign,
    TextMessageIcon,
    BOverlay,
    BAlert,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    // BAlert,
    TurtleRentLogo,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      authentication_method: '',
      loading: false,
      mfa_method: '',
      // validation rules
      required
    }
  },
  computed: {
    userData() {
      return this.$store.getters[`auth/userData`];
    },
    getUserMaskedPhoneNumber(){
      return this.maskPhoneNumber(get(this.userData, 'phone', "0248801964"));
    },
    getMaskedEmail() {
      return this.maskEmail(get(this.userData, 'email', ""))
    }, 
  },
  methods: {
    toggleCards(card) {
      const cards = document.getElementsByClassName('active');

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < cards.length; i++) {
        cards.item(i).classList.toggle('active')
      }

      //
      const cardElement = document.getElementById(card);
      cardElement.classList.toggle('active');
    },
    async selectMFAMethod(mfa_method) {
      try {
        this.mfa_method = mfa_method;
        const payload = { mfa_method };
        const response = await this.useJwt()
            .mfaService
            .selectMfaMethod(payload);
        const response_data = response.data.data;

        // check method authentication and route appropriately
        this.checkAndRoute(response_data);
      } catch (error) {
        console.error('Error here', error)
        const error_message = get(error, "response.data.message") || error.message;
        console.log("error_message", error_message)
        this.error = error_message

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Something Usual happened',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: error_message,
          },
        });
      }
    },
    checkAndRoute(response) {
      const is_app_mfa_method = !isEmpty(response);
      if (is_app_mfa_method) {
        this.$router.push({
          name: "security-activation-app",
          params: { authentication_data: {
              qr_data_url: response.qr_data_url,
              secret: response.secret
            } }
        })
            .then(() => {
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: 'OT',
              //     icon: 'EditIcon',
              //     variant: 'success',
              //     text: 'Kindly secure your account'
              //   },
              // });
            })
            .catch(() => {
            });
      } else {
        this.$router.push({
          name: "security-mfa-activation",
          params: { mfa_method: this.mfa_method }
        })
            .then(() => {
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: 'OT',
              //     icon: 'EditIcon',
              //     variant: 'success',
              //     text: 'Kindly secure your account'
              //   },
              // });
            })
            .catch(() => {
            });
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

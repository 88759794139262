<template>
  <b-overlay :show="loading">
    <add-or-update-offering-type-form @create="createOfferingType" />
  </b-overlay>
</template>

<script>
import { get } from 'lodash';
import { BOverlay } from 'bootstrap-vue'

import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddOrUpdateOfferingTypeForm from '@core/components/shared/admin/offering-types/AddOrUpdateOfferingTypeForm.vue'

export default {
  components: {
    vSelect,
    BOverlay,
    AddOrUpdateOfferingTypeForm,
  },
  data(){
    return {
      loading: false,
      errorMessage: '',
    }
  },
  methods: {
    async createOfferingType(payload){
      try {
        this.loading = true;
        this.errorMessage = '';
        
        await this.useJwt().adminService.createOfferingType(payload);
    
        await this.$router.push({
          name: "admin-offering-types"
        });

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'EditIcon',
            variant: 'success',
            text: `Offering type added successfully`
          },
        });
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

export default [
  {
    path: '/home',
    name: 'admin-home',
    component: () => import('@/pages/admin/home'),
    meta: {
      action: "read",
      pageTitle: 'Dashboard',
      resource: "AdminRoute",
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
      roles: ["admin"],
      requireAuth: true,
      logPageName: "Admin Dashboard",
    },
  },
  /** Admin Users */
  {
    path: '/members',
    name: 'admin-users',
    component: () => import('@/pages/admin/users/list'),
    meta: {
      action: "read",
      pageTitle: 'Users',
      resource: "AdminListUsersRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List Members',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Admin List Members",
      showPagination: true,
      roles: ["admin"],
      permissions: ["users:CanList"]
    },
  },
  {
    path: '/members/:id',
    name: 'admin-user-single',
    component: () => import('@/pages/admin/users/view'),
    meta: {
      action: "read",
      pageTitle: 'Member Details',
      resource: "users",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List Members',
          to: { name: 'admin-users' },
        },
        {
          text: 'Member Details',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["users:CanView"],
      logPageName: "Admin Memeber Details",
    },
  },
  {
    name: 'admin-update-user',
    path: '/members/:id/update',
    component: () => import('@/pages/admin/users/update'),
    resource: "users",
    meta: {
      action: "read",
      pageTitle: 'Update Member',
      resource: "AdminUpdateUserRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List Members',
          to: { name: 'admin-users' },
        },
        {
          text: 'Update',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      logPageName: "Admin Update Member",
      permissions: ["users:CanUpdate"]
    },
  },
  {
    path: '/churches',
    name: 'admin-churches',
    component: () => import('@/pages/admin/church/list'),
    meta: {
      action: "read",
      pageTitle: 'Churches',
      resource: "AdminListUsersRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List Churches',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Admin List Churches",
      showPagination: true,
      roles: ["admin"],
      permissions: ["users:CanList"]
    },
  },
  {
    name: 'admin-update-church',
    path: '/churches/:id/update',
    component: () => import('@/pages/admin/church/update'),
    resource: "users",
    meta: {
      action: "read",
      pageTitle: 'Update Church',
      resource: "AdminUpdateUserRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List Churches',
          to: { name: 'admin-churches' },
        },
        {
          text: 'Update',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      logPageName: "Admin Update Church",
      permissions: ["users:CanUpdate"]
    },
  },
  /** ADMIN USERS */
  {
    path: '/admin-users',
    name: 'admin',
    component: () => import('@/pages/admin/users/list/admins'),
    meta: {
      action: "read",
      pageTitle: 'Users',
      resource: "AdminListUsersRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List Users',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      showPagination: true,
      permissions: ["admin:CanList"],
      logPageName: "Admin List Users",
    },
  },
  {
    name: 'admin-new-user',
    path: '/admin-users/new',
    component: () => import('@/pages/admin/users/new'),
    meta: {
      action: "read",
      pageTitle: 'New Staff',
      resource: "AdminAddUsersRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List Users',
          to: { name: 'admin' },
        },
        {
          text: 'New Staff',
          active: true,
        },
      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["admin:CanAddNew"],
      logPageName: "Admin Create User",
    },
  },
  {
    name: 'admin-single',
    path: '/admin-users/:id',
    component: () => import('@/pages/admin/users/update/admin'),
    meta: {
      action: "read",
      pageTitle: 'User Details',
      resource: "admin",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List Admins',
          to: { name: 'admin' },
        },
        {
          text: 'User Details',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["admin:CanView"],
      logPageName: "Admin User Details",
    },
  },
  /** END OF ADMIN USERS */

  /**
   * ADMIN TRANSACTIONS
   */
  {
    path: '/offerings',
    name: 'admin-transactions',
    component: () => import('@/pages/admin/transactions'),
    meta: {
      action: "read",
      pageTitle: 'Offerings',
      resource: "AdminListTransactionsRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List Offerings',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      showPagination: true,
      permissions: ["pre_transactions:CanList"],
      logPageName: "Admin List Offerings",
    },
  },
  {
    path: '/generate-offerings-report',
    name: 'generate-offerings-report',
    component: () => import('@/pages/admin/transaction-report'),
    meta: {
      action: "read",
      pageTitle: 'Offering Report',
      resource: "AdminListTransactionsRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List Offerings',
          to: { name: 'admin-transactions' },
        },
        {
          text: 'Generate Report',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      showPagination: true,
      permissions: ["pre_transactions:CanList"],
      logPageName: "Admin Generate Offerings Report",
    },
  },

  /**
   * ADMIN SETTLEMENTS
   */
  {
    path: '/settlements',
    name: 'admin-settlements',
    component: () => import('@/pages/admin/settlements'),
    meta: {
      action: "read",
      pageTitle: 'Settlements',
      resource: "AdminListSettlementsRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List Settlements',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      showPagination: true,
      permissions: ["settlements:CanList"],
      logPageName: "Admin List Settlements",
    },
  },

  /**
   * ADMIN SETTLEMENTS
   */
  {
    path: '/recurring-offerings',
    name: 'admin-recurring-offerings',
    component: () => import('@/pages/admin/recurring-offerings'),
    meta: {
      action: "read",
      pageTitle: 'Recurring Offering Pledge',
      resource: "AdminListSettlementsRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List Recurring Offerings',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      showPagination: true,
      permissions: ["offering_pledge:CanList"],
      logPageName: "Admin List Recurring Offerings",
    },
  },
  {
    path: '/church-map',
    name: 'admin-church-map',
    component: () => import('@/pages/admin/church-map/list.vue'),
    meta: {
      action: "read",
      pageTitle: 'Users',
      resource: "AdminListChurchMapsRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'Church Map',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Admin List Church Map",
      showPagination: true,
      roles: ["admin"],
      permissions: ["church_map:CanList"]
    },
  },


  // Admin Logs
  {
    path: '/admin-logs',
    name: 'admin-logs',
    component: () => import('@/pages/admin/logs/admin'),
    meta: {
      action: "read",
      pageTitle: 'Admin Logs',
      resource: "AdminListAdminLogsRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'Admin Logs',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      showPagination: true,
      permissions: ["admin_logs:CanList"],
      logPageName: "Admin List Logs",
    },
  },
  {
    path: '/user-logs',
    name: 'user-logs',
    component: () => import('@/pages/admin/logs/user'),
    meta: {
      action: "read",
      pageTitle: 'User Logs',
      resource: "AdminListLogsRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'User Logs',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      showPagination: true,
      permissions: ["logs:CanList"],
      logPageName: "User List Logs",
    },
  },
  {
    path: '/audit-trail',
    name: 'audit-trail',
    component: () => import('@/pages/admin/logs/audit-trail'),
    meta: {
      action: "read",
      pageTitle: 'Audit Trail',
      resource: "AdminListLogsRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'Audit Trail',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      showPagination: true,
      permissions: ["audit_trail:CanList"],
      logPageName: "List Audit Trail",
    },
  },


  /** ADMIN OFFERING TYPES */
  {
    path: '/offering-types',
    name: 'admin-offering-types',
    component: () => import('@/pages/admin/offering-types/list'),
    meta: {
      action: "read",
      pageTitle: 'Offering Types',
      resource: "AdminListOfferingTypesRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List Offering Types',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      showPagination: true,
      permissions: ["offering_types:CanList"],
      logPageName: "Admin List Offering Types",
    },
  },
  {
    name: 'admin-new-offering-type',
    path: '/offering-types/new',
    component: () => import('@/pages/admin/offering-types/new'),
    meta: {
      action: "read",
      pageTitle: 'New Offering Type',
      resource: "AdminAddOfferingTypeRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List Offering Types',
          to: { name: 'admin-offering-types' },
        },
        {
          text: 'New Offering Type',
          active: true,
        },
      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["offering_types:CanAddNew"],
      logPageName: "Admin Create Offering Type",
    },
  },
  {
    name: 'admin-update-offering-type',
    path: '/offering-types/:id',
    component: () => import('@/pages/admin/offering-types/update'),
    meta: {
      action: "read",
      pageTitle: 'Update Offering Type',
      resource: "admin",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List Offering Types',
          to: { name: 'admin-offering-types' },
        },
        {
          text: 'Update Offering Type',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["offering_types:CanView"],
      logPageName: "Admin Update Offering Type",
    },
  },
  /** Admin Settings */
  {
    path: '/settings',
    name: 'admin-settings',
    component: () => import('@/pages/admin/settings'),
    meta: {
      action: "read",
      pageTitle: 'Settings',
      resource: "AdminSettingsRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'Settings',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["settings:CanView"],
      logPageName: "Admin View Settings",
    },
  },
  {
    path: '/policies',
    name: 'admin-policies',
    component: () => import('@/pages/admin/administrations/policies/list-policies'),
    meta: {
      action: "read",
      pageTitle: 'Policies',
      resource: "AdminListPermissionsRoute",
      breadcrumb: [
        {
          text: 'List Policies',
          to: { name: 'admin-policies' },
        },
        {
          text: 'List',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["policy:CanList"],
      logPageName: "Admin List Policies",
    },
  },
  {
    path: '/policies/:id',
    name: 'admin-view-policies',
    component: () => import('@/pages/admin/administrations/policies/view-policy'),
    meta: {
      action: "read",
      pageTitle: 'Policy',
      resource: "policy",
      breadcrumb: [
        {
          text: 'List Policies',
          to: { name: 'admin-policies' },
        },
        {
          text: 'View',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["policy:CanView"],
      logPageName: "Admin View Policies",
    },
  },
  {
    path: '/create-policy',
    name: 'admin-new-policy',
    component: () => import('@/pages/admin/administrations/policies/new-policy'),
    meta: {
      action: "read",
      pageTitle: 'Add Policy',
      resource: "AdminListPermissionsRoute",
      breadcrumb: [
        {
          text: 'List Policies',
          to: { name: 'admin-policies' },
        },
        {
          text: 'New',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["policy:CanAddNew"],
      logPageName: "Admin Create Policy",
    },
  },
  /** Admin Permission Resource */
  {
    path: '/resources',
    name: 'admin-resources',
    component: () => import('@/pages/admin/administrations/resources/list-resources'),
    meta: {
      action: "read",
      pageTitle: 'Resources',
      resource: "AdminListPermissionsRoute",
      breadcrumb: [
        {
          text: 'List Resources',
          to: { name: 'admin-resources' },
        },
        {
          text: 'List',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      showPagination: true,
      permissions: ["resource:CanList"],
      logPageName: "Admin List Resources",
    },
  },
  {
    path: '/create-resource',
    name: 'admin-new-resource',
    component: () => import('@/pages/admin/administrations/resources/new-resource'),
    meta: {
      action: "read",
      pageTitle: 'Resource',
      resource: "AdminListPermissionsRoute",
      breadcrumb: [
        {
          text: 'Resources',
          to: { name: 'admin-resources' },
        },
        {
          text: 'Add Resource',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      logPageName: "Admin New Resources",
      permissions: ["resource:CanAddNew"],
    },
  },
  {
    path: '/update-resource/:id',
    name: 'admin-update-resource',
    component: () => import('@/pages/admin/administrations/resources/update-resource'),
    meta: {
      action: "read",
      pageTitle: 'Resource',
      resource: "resource",
      breadcrumb: [
        {
          text: 'Resources',
          to: { name: 'admin-resources' },
        },
        {
          text: 'Update Resource',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["resource:CanView"],
      logPageName: "Admin Update Resources",
    },
  },

  /** Admin Permission Resource Actions */
  {
    path: '/resource-actions',
    name: 'admin-resource-actions',
    component: () => import('@/pages/admin/administrations/resource-actions/list-resource-actions'),
    meta: {
      action: "read",
      pageTitle: 'Resource Actions',
      resource: "AdminListPermissionsRoute",
      breadcrumb: [
        {
          text: 'List Resource Actions',
          to: { name: 'admin-resource-actions' },
        },
        {
          text: 'List',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      showPagination: true,
      permissions: ["resource_action:CanList"],
      logPageName: "Admin List Resource Actions",
    },
  },
  {
    name: 'admin-new-resource-action',
    path: '/create-resource-action/:id',
    component: () => import('@/pages/admin/administrations/resource-actions/new-resource-action'),
    meta: {
      action: "read",
      pageTitle: 'Add Resource Action',
      resource: "AdminListPermissionsRoute",
      breadcrumb: [
        {
          text: 'Resource Actions',
          to: { name: 'admin-resource-actions' },
        },
        {
          text: 'Add Resource Action',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      showPagination: true,
      logPageName: "Admin Add Resource Actions",
      permissions: ["resource_action:CanView"],
    },
  },
  {
    name: 'admin-update-resource-action',
    path: '/update-resource-action/:id/:resource_id',
    component: () => import('@/pages/admin/administrations/resource-actions/update-resource-action'),
    meta: {
      action: "read",
      pageTitle: 'Resource Action',
      resource: "resource_action",
      breadcrumb: [
        {
          text: 'Resources',
          to: { name: 'admin-resources' },
        },
        {
          text: 'Update Resource Action',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      showPagination: true,
      permissions: ["resource_action:CanView"],
      logPageName: "Admin Update Resource Action",
    },
  },

  /** Admin Permission User Roles */
  {
    path: '/user-roles',
    name: 'admin-user-roles',
    component: () => import('@/pages/admin/administrations/user-roles/list'),
    meta: {
      action: "read",
      pageTitle: 'User Role',
      resource: "AdminListPermissionsRoute",
      breadcrumb: [
        {
          text: 'List User Roles',
          to: { name: 'admin-user-roles' },
        },
        {
          text: 'List',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      showPagination: true,
      permissions: ["user_role:CanList"],
      logPageName: "Admin List User Roles",
    },
  },
  {
    path: '/create-user-role',
    name: 'admin-new-user-role',
    component: () => import('@/pages/admin/administrations/user-roles/new-user-role'),
    meta: {
      action: "read",
      pageTitle: 'Add Admin User Role',
      resource: "user_role",
      breadcrumb: [
        {
          text: 'User Roles',
          to: { name: 'admin-user-roles' },
        },
        {
          text: 'Add Admin User Role',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      logPageName: "Add Admin User Role",
      permissions: ["user_role:CanAddNew"],
    },
  },
  {
    path: '/update-user-role/:id',
    name: 'admin-update-user-role',
    component: () => import('@/pages/admin/administrations/user-roles/update-user-role'),
    meta: {
      action: "read",
      pageTitle: 'Add Admin User Role',
      resource: "user_role",
      breadcrumb: [
        {
          text: 'User Roles',
          to: { name: 'admin-user-roles' },
        },
        {
          text: 'Update Admin User Role',
          active: true,
        }
      ],
      roles: ["admin"],
      requireAuth: true,
      permissions: ["user_role:CanView"],
      logPageName: "Update Admin User Role",
    },
  },
]

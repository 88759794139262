<template>
  <b-overlay>
    <add-update-resource-action
        ref="resourceActionForm"
        :resource-id="resourceId"
        @create="onNewResourceAction"
    />
  </b-overlay>
</template>

<script>
import { get } from "lodash";
import { BOverlay } from "bootstrap-vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AddUpdateResourceAction from "@/pages/admin/administrations/resource-actions/add-update-resource-action.vue";

export default {
  name: "NewResourceAction",
  components: {
    AddUpdateResourceAction,
    BOverlay
  },
  data() {
    return {
      loading: false,
      resourceId: "",
    }
  },
  created() {
    this.getSelectedResource();
  },
  methods: {
    async getSelectedResource() {
      try {
        this.loading = true;

        const { id } = this.$route.params

        this.resourceId = id;
        const response = await this.useJwt()
            .adminService
            .getResources();

        this.resources = response.data.data;
        this.filterResource(id);
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    filterResource(resource_id) {
      [this.resource] = this.resources.filter(value => value.id === resource_id);
    },
    async onNewResourceAction(payload) {
      try {
        this.loading = true;

        await this.useJwt()
            .adminService
            .createResourceAction(payload);

        await this.$router.push({
          name: "admin-resources"
        });
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'EditIcon',
            variant: 'success',
            text: `Resource action created successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <b-overlay :show="loading">
    <add-update-resource ref="newResourceForm" @create="onAddResource" />
  </b-overlay>
</template>

<script>
import { get } from "lodash";
import { BOverlay } from "bootstrap-vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AddUpdateResource from "@/pages/admin/administrations/resources/add-update-resource.vue";




export default {
  name: "NewResource",
  components: {
    AddUpdateResource,
    BOverlay,

  },
  data(){
    return {
      title: "",
      loading: false,
  }
  },
  methods: {
    async onAddResource(payload){
      try {
        this.loading = true;

        // const success = await this.$refs.newResourceForm.validate();
        // if (!success) {
        //   return;
        // }

        await this.useJwt()
            .adminService
            .createResource(payload)

        await this.$router.push({
          name: "admin-resources"
        });
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'EditIcon',
            variant: 'success',
            text: `Resource created successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/base/pages/app-general.scss';
</style>

<template>
  <b-modal
    id="add-or-update"
    :visible="isAddOrUpdateActive"
    centered
    hide-footer
    hide-header
    no-close-on-esc
    no-close-on-backdrop
    @hidden="resetForm"
    @hide="resetForm"
    @change="(val) => $emit('update:is-add-or-update-active', val)"
  >
    <template #default="{ hide }">
      <b-overlay :show="loading" class="px-2">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header py-1" style="border-bottom: 1px solid #000000;">
          <h5 class="mb-0" style="font-weight: 400; font-size: 14px; line-height: 160%;">
            {{ isUpdate ? 'Update Church Map' : 'Add New Church Map' }}
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <validation-observer
          #default="{ handleSubmit }"
          ref="update_modal_form"
        >
          <b-form
            class="my-1"
            @submit.prevent="handleSubmit(onCreateOrUpdate)"
            @reset.prevent="resetForm"
          >

            <b-alert
              v-if="errorMessage"
              variant="danger"
              :show="true"
            >
              <div class="alert-body font-small-2">
                <b-card-text class="mb-0">
                  {{ errorMessage }}
                </b-card-text>
              </div>
            </b-alert>
            
            <validation-provider
              #default="validationContext"
              name="Region"
              rules="required"
            >
            <b-form-group
              label="Region"
              label-for="region"
            >
              <template #label>
                <span>Region<sup style="color: tomato">*</sup></span>
              </template>

              <b-form-input
                id="region"
                v-model="new_data.region"
                autofocus
                placeholder=""
                :disabled="disableForm"
                :state="getValidationState(validationContext)"
              />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Area -->
            <validation-provider
              #default="validationContext"
              name="Area"
              rules="required"
            >
              <b-form-group
                label="Area"
                label-for="area"
              >
                <b-form-input
                  id="area"
                  v-model="new_data.area"
                  :state="getValidationState(validationContext)"
                  :disabled="disableForm"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- District -->
            <validation-provider
              #default="validationContext"
              name="District"
              rules="required"
            >
              <b-form-group
                label="District"
                label-for="district"
              >
                <b-form-input
                  id="district"
                  v-model="new_data.district"
                  :state="getValidationState(validationContext)"
                  :disabled="disableForm"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            
            <!-- Assembly -->
            <validation-provider
              #default="validationContext"
              name="Assembly"
              rules="required"
            >
              <b-form-group
                label="Assembly"
                label-for="assembly"
              >
                <b-form-input
                  id="assembly"
                  v-model="new_data.assembly"
                  :state="getValidationState(validationContext)"
                  :disabled="disableForm"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              name="Assembly Type"
              rules="required"
            >
            <b-form-group
                label="Assembly Type"
                label-for="assembly-type"
                class="mb-2"
              >
                <v-select
                  id="assembly-type"
                  v-model="new_data.assembly_type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="assemblyTypeOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                />
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Longitude"
              :rules="{
                required: true,
                max_value: 1000000
              }"
            >
              <b-form-group
                label="Longitude"
                label-for="Longitude"
              >
                <b-form-input
                  id="longitude"
                  v-model="new_data.longitude"
                  :state="getValidationState(validationContext)"
                  :disabled="disableForm"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Latitude"
              :rules="{
                required: true,
                max_value: 1000000
              }"
            >
              <b-form-group
                label="Latitude"
                label-for="Latitude"
              >
                <b-form-input
                  id="latitude"
                  v-model="new_data.latitude"
                  :state="getValidationState(validationContext)"
                  :disabled="disableForm"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="disableForm"
              >
                {{ isUpdate ? 'Update' : 'Add' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-modal>
</template>

<script>
import {
  BOverlay, BSidebar, BForm, BFormGroup, BFormInput, 
  BFormTextarea, BFormInvalidFeedback, BButton, BFormFile,
  BAlert, BCardText, BModal, BMedia, BMediaBody, BMediaAside, BImg
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { get, cloneDeep, omit, pick } from 'lodash';

import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

const rawEventCenterData = {
  region: "",
  assembly: "",
  district: "",
  area: "",
  assembly_type: "",
  longitude: "",
  latitude: ""
}

export default {
  components: {
    BImg,
    BForm,
    BAlert,
    BModal,
    BMedia, 
    BButton,
    vSelect,
    BOverlay,
    BSidebar,
    BCardText,
    BFormFile,
    BFormGroup,
    BMediaBody, 
    BFormInput,
    BMediaAside,
    BFormTextarea,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddOrUpdateActive',
    event: 'update:is-add-or-update-active',
  },
  props: {
    isAddOrUpdateActive: {
      type: Boolean,
      required: true,
    },
    updateData: {
      type: Object,
      default: null,
      required: false
    },
  },
  data() {
    return {
      new_data: cloneDeep(rawEventCenterData),
      loading: false,
      errorMessage: "",

      avatarImage: null,
      avatarImagePath: "",
      required,
      assemblyTypeOptions: [
        'None',
        'Central Assembly',
        'Church Land',
        'Farm',
        'Land',
        'Local Assembly',
        'Mission House',
        'Office',
        'Prayer Centre',
        'School',
        'Worship Centre'
      ].map(value => ({
        value,
        label: value
      }))
    }
  },
  computed: {
    isUpdate(){
      return !!this.updateData._id
    },
    disableForm(){
      if (this.isUpdate){
        return !this.can("church_map:CanUpdate")
      }

      return !this.can("church_map:CanAddNew")
    },
  },
  watch: {
    updateData: {
      handler(updateData){
        if (updateData){
          const fields = ['_id', 'district', 'region', 'area', 'assembly', 'assembly_type', 'longitude', 'latitude']
          this.new_data = pick(updateData, fields)
          this.avatarImagePath = this.getValueFromSource(updateData, 'avatar.path');
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async onCreateOrUpdate(){
      try {
        this.loading = true;
        this.errorMessage = "";

        const success = await this.$refs.update_modal_form.validate();
        if (!success){
          return;
        }

        const messages = {
          update: {
            title: "Confirm Church Map Update",
            text: "You are about to update details of a church map.",
            icon: "warning"
          },
          new: {
            title: "Create Church Map",
            text: "You are about to create a church map",
            icon: "info"
          }
        }

        /* eslint-disable no-nested-ternary */
        const message = this.isUpdate ? messages.update : messages.new;
        const { title, text, icon } = message;

        const result = await this.$swal({
          title,
          text,
          icon,
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });
        if (!result.isConfirmed) {
          return;
        }
        
        if (this.isUpdate){
          const id = this.new_data._id
          const update_data = omit(this.new_data, "_id");

          if (!this.can("church_map:CanUpdate")) {
            return
          }

          await this.useJwt().adminService.updateChurchMap(id, update_data);
        } else {
          if (!this.can("church_map:CanAddNew")) {
            return
          }

          await this.useJwt().adminService.createChurchMap(this.new_data);
        }
        this.$emit("refetchData");
        this.$emit('update:is-add-or-update-active', false);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'AlertQuestionIcon',
            variant: 'success',
            text: `Event caterer ${this.isUpdate ? 'updated' : 'added'} successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    resetForm() {
      this.$emit("resetUpdateData")
      this.new_data = {};
      this.avatarImage = null;
      this.avatarImagePath = '';
      if (this.$refs.update_modal_form) {
        this.$refs.update_modal_form.reset()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-or-update {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>

<template>
<div>
  <div>
    <b-dropdown variant="link" class="p-0" no-caret :right="$store.state.appConfig.isRTL">
      <template #button-content>
        <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
      </template>

      <b-dropdown-item v-if="canUpdateUser" :to="{ name: 'admin-update-user', params: { id: getValueFromSource(userData, '_id') } }">
        <feather-icon icon="EditIcon" />
        <span class="align-middle ml-50">Edit</span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BCardHeader,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BBadge,
  BButton,
  BRow,
  BCol,
  BCardImg,
  BLink
} from 'bootstrap-vue';

import Ripple from 'vue-ripple-directive';
import VueEasyLightbox from 'vue-easy-lightbox';

// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BOverlay,
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BCardImg,
    BLink,
    VueEasyLightbox
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imageLoading: true,
      visible: false,
      index: 0, // default: 0
    }
  },
  computed: {
    canUpdateUser() {
      return this.can("users:CanUpdate");
    },
  },
  methods: {}
}
</script>

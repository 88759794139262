<template>
<section>
  <b-card>
    <b-row>
      <b-col cols="12" md="8">
        <div>
          <validation-observer ref="newResourceForm" #default="{invalid}">
            <b-form ref="form" @submit.prevent="onCreateOrUpdateResource">
              <div class="my-1">
                <b-form-group label="Title" label-for="title" label-cols-sm="4" label-cols-lg="4" content-col-sm content-cols-lg-7>
                  <validation-provider #default="{ errors }" name="title" :rules="`required`">
                    <b-form-input id="title" v-model="resource.title" placeholder="Enter resource title" :state="errors.length > 0 ? false : null" />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <div v-if="!isUpdate" class="my-1">
                <b-form-group label="Resource Name" label-for="resource_name" label-cols-sm="4" label-cols-lg="4" content-col-sm content-cols-lg-7>
                  <validation-provider #default="{ errors }" name="resource_name" vid="resource_name" rules="required">
                    <b-form-input id="Resource Name" v-model="resource.resource_name" :readonly="isUpdate" placeholder="Enter name of resource" :state="errors.length > 0 ? false : null" />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <div v-if="!isUpdate && !resource.is_a_service" class="my-1">
                <b-form-group label="Collection Name" label-for="collection_name" label-cols-sm="4" label-cols-lg="4" content-col-sm content-cols-lg-7>
                  <validation-provider #default="{ errors }" name="collection_name" vid="collection_name" rules="required">
                    <b-form-input id="Collection Name" v-model="resource.collection_name" :readonly="isUpdate" placeholder="Enter collection name for appropriate resource" :state="errors.length > 0 ? false : null" />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <div class="my-1">
                <b-form-group label="Description" label-for="description" label-cols-sm="4" label-cols-lg="4" content-col-sm content-cols-lg-7>
                  <validation-provider #default="{ errors }" name="description" vid="description" rules="required">
                    <b-form-textarea id="Description" v-model="resource.description" placeholder="Provide a brief description for the resource" :state="errors.length > 0 ? false : null" />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="my-1">
                <b-form-group label-cols-sm="4" label-cols-lg="4" content-col-sm content-cols-lg-7>
                  <validation-provider #default="{ errors }" name="Service Resource" rules="required">
                    <b-form-checkbox id="service_resource" v-model="resource.is_a_service" :disabled="isUpdate" name="service_resource">
                      <p>Service Resource</p>
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="my-1">
                <b-form-group label-cols-sm="4" label-cols-lg="4" content-col-sm content-cols-lg-7>
                  <validation-provider #default="{ errors }" name="Active" rules="required">
                    <b-form-checkbox id="is_active" v-model="resource.is_active" name="is_active">
                      <p>Is active</p>
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <div v-if="!isUpdate" class="mt-3">
                <h5> Default Resource Actions </h5>
              </div>

              <div v-if="!isUpdate" class="my-1">
                <b-form-group label-cols-sm="4" label-cols-lg="4" content-col-sm content-cols-lg-7>
                  <validation-provider #default="{ errors }" name="Default Actions" rules="required">
                    <b-form-checkbox id="default_actions" v-model="include_default_actions" name="default_actions">
                      <p>Include Default Actions</p>
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div v-if="include_default_actions">
                <div v-for="([key], i) in Object.entries(default_actions)" :key="i">
                  <div class="my-1">
                    <b-form-group label-cols-sm="4" label-cols-lg="4" content-col-sm content-cols-lg-7>
                      <validation-provider #default="{ errors }" :name="key" rules="required">
                        <b-form-checkbox :id="key" v-model="default_actions[key]" :name="key">
                          <p>{{ key }}</p>
                        </b-form-checkbox>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>


              </div>
              <div>
                <b-button type="submit" variant="primary" :disabled="invalid">
                  {{ isUpdate ? 'Update Resource' : 'Create Resource' }}
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>

      </b-col>
    </b-row>
  </b-card>

  <div v-if="isUpdate">
    <div>
      <div class="d-flex justify-content-end align-items-center">
        <div class="pb-1 pt-2">
          <b-button variant="primary" @click="toggleModal">
            Add Resource Action
          </b-button>
        </div>
      </div>
    </div>
    <b-card>
      <div class="card-body">
        <div>
          <list-table
              :records="resource_actions"
              :columns="tableColumns"
              :current-page-number.sync="currentPage"
              :total-records="totalRecords"
              :search-filter.sync="searchFilter"
              @row-clicked="onItemClicked"
          >
            <template #cell(actions)="data">
              <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                </template>

                <b-dropdown-item :to="{
                    name: 'admin-update-resource-action',
                    params: {
                      id: data.item._id,
                      resource_id: getValueFromSource(resource, '_id')
                    },
                  }"
                >
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Update</span>
                </b-dropdown-item>

                <b-dropdown-item @click="deleteResourceAction(data.item._id)">
                  <feather-icon class="text-danger" icon="TrashIcon" />
                  <span class="align-middle ml-50 text-danger">Delete</span>
                </b-dropdown-item>

              </b-dropdown>
            </template>
          </list-table>
        </div>
      </div>
    </b-card>
  </div>

  <div>
    <b-modal ref="add-resource-action" size="lg" hide-footer centered hide-header-close>
      <div>
        <div class="mb-2">
          <h2>Add Resource Action for Resource </h2>
        </div>
      </div>
      <div>
        <add-update-resource-action
            ref="resourceActionForm"
            :resource-id="getValueFromSource(resource, '_id')"
            @close-modal="onCloseModal"
            @create="onNewResourceAction"
        />
      </div>
    </b-modal>
  </div>


</section>
</template>

<script>
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UpdateResourceAction from "@/pages/admin/administrations/resource-actions/update-resource-action.vue";
import AddUpdateResourceAction from "@/pages/admin/administrations/resource-actions/add-update-resource-action.vue";

import { required } from "@core/utils/validations/validations";
import {
  BButton,
  BCard,
  BCol,
  BRow,
  BForm,
  BModal,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
  BFormCheckboxGroup
} from "bootstrap-vue";
import { cloneDeep, debounce, get } from "lodash";
import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate";
import vSelect from "vue-select";
import ListTable from "@core/components/ListTable/ListTable.vue";

const rawResourceData = {
  title: "",
  resource_name: "",
  description: "",
  collection_name: "",
  is_a_service: false,
  is_active: true
}

const watchHandler = {
  handler() {
    this.debounceFetchAllData();
  },
  immediate: false,
};

export default {
  name: "AddUpdateResource",
  components: {
    UpdateResourceAction,
    ListTable,
    ValidationProvider,
    ValidationObserver,
    AddUpdateResourceAction,
    BCol,
    BRow,
    BCard,
    BModal,
    BForm,
    vSelect,
    BButton,
    BDropdown,
    BFormInput,
    BFormGroup,
    BDropdownItem,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  props: {
    updatingResource: {
      type: [Object, null, undefined],
      default: null,
      required: false
    },
  },
  data() {
    return {
      title: "",
      resource: {
        ...rawResourceData
      },
      loading: false,
      include_default_actions: false,
      default_actions: {
        canAddNew: false,
        canView: false,
        canUpdate: false,
        canList: false,
        canDelete: false,
        canImport: false,
        canExport: false
      },
      resource_actions: [],
      searchFilter: null,
      tableColumns: [
        { key: 'name', sortable: false, label: 'Resource Actions' },
        { key: 'access_level', sortable: false, label: 'Access Levels' },
        { key: 'actions' }
      ],
      debounceFetchAllData: () => {},
      required
    }
  },
  computed: {
    isUpdate() {
      return !!this.updatingResource;
    },
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value) {
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value) {
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
  },
  watch: {
    updatingResource: {
      handler(update) {
        if (update) {
          this.resource = get(update, 'data');
        }
      },
      deep: true,
      immediate: true
    },
    perPage: {
      handler() {
        this.currentPage = 1;
        this.debounceFetchAllData();
      },
      immediate: false,
    },
    currentPage: watchHandler,
    searchFilter: watchHandler,
  },
  created() {
    this.debounceFetchAllData = debounce(this.fetchResourceActions, 500);

    const {
      status,
      page = 1,
      search = "",
      limit = this.perPage
    } = this.$route.query;

    this.currentPage = +page;
    this.searchFilter = search;
    this.statusFilter = status;
    this.perPage = +limit;
  },
  methods: {
    async onCreateOrUpdateResource() {
      try {
        this.loading = true

        const success = await this.$refs.newResourceForm.validate();
        if (!success) {
          return;
        }

        const payload = {
          title: this.resource.title,
          description: this.resource.description,
          is_active: this.resource.is_active
        }

        if (!this.isUpdate) {
          payload.resource_name = this.resource.resource_name;
          payload.collection_name = this.resource.collection_name;
          payload.is_a_service = this.resource.is_a_service;
          payload.include_default_actions = this.include_default_actions;
          payload.default_actions = this.default_actions;
        }

        if (this.isUpdate) {
          this.$emit("update", payload);
        } else {
          this.$emit("create", payload);
        }
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async onNewResourceAction(payload) {
      try {
        this.loading = true;

        await this.useJwt()
            .adminService
            .createResourceAction(payload);


        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'EditIcon',
            variant: 'success',
            text: `Resource action created successfully`
          },
        });
        this.debounceFetchAllData();
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async deleteResourceAction(resource_action_id) {
      try {
        this.loading = true;

        const deleteConfirmation = await this.$swal({
          title: 'Delete this Resource Action?',
          text: "You are about to delete a resource action. Continue?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (!deleteConfirmation.value) {
          return;
        }

        await useJwt.adminService.deleteResourceAction(resource_action_id);
        this.debounceFetchAllData();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Successful',
            icon: 'EditIcon',
            variant: 'success',
            text: 'Resource Action Deleted Successfully'
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchResourceActions() {
      try {
        this.loading = true;

        const {
          perPage: limit,
          currentPage: page,
          searchFilter: search,
        } = this;

        const query = {
          limit,
          page,
          search,
          resource_id: this.getValueFromSource(this.resource, 'id')
        };
        this.$router.push({ query }).catch(() => {});

        const request = await useJwt.adminService.getResourceActions(query);
        const { data, pagination } = request.data;

        this.totalRecords = pagination.totalRecords;
        this.resource_actions = data;
      } catch (error) {
        const error_message =
            get(error, "response.data.message") || error.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
        return [];
      } finally {
        this.loading = false;
        this.showListTable = true;
      }
    },
    onItemClicked(rowItem) {
      console.log('row item ', rowItem);
      this.$router.push({
        name: "admin-update-resource-action",
        params: {
          id: rowItem._id,
          resource_id: this.getValueFromSource(this.resource, '_id')
        },
      });
    },
    toggleModal() {
      this.$refs['add-resource-action'].toggle('#toggle-btn')
    },
    resetForm() {
      Object.keys(cloneDeep(this.resource)).forEach(key => {
        const resetValue = get(rawResourceData, key);
        this.resource[key] = resetValue
        this.$set(this.resource, resetValue);
      });
    },
    onCloseModal(payload) {
      const close_modal = this.getValueFromSource(payload, 'state');
      if (close_modal) {
        this.toggleModal();
      }
    }
  }
}
</script>

<style scoped>

</style>

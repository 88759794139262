export default [
  {
    path: '/',
    name: 'app-root',
    component: () => import('@/pages/admin/auth/login'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      redirectIfLoggedIn: true,
      roles: ["admin", "anonymous"]
    },
  },
  {
    path: '/auth/login',
    name: 'auth-login',
    component: () => import('@/pages/admin/auth/login'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      redirectIfLoggedIn: true,
      roles: ["admin", "anonymous"]
    },
  },
  {
    path: '/auth/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/pages/admin/auth/forgot-password'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      redirectIfLoggedIn: true,
      roles: ["admin", "anonymous"]
    },
  },
  {
    path: '/auth/forgot-password-mfa',
    name: 'auth-forgot-password-mfa',
    component: () => import('@/pages/admin/auth/forgot-password/mfa-password-reset'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      redirectIfLoggedIn: true,
      roles: ["admin", "anonymous"]
    },
  },
  {
    path: '/auth/reset-password',
    name: 'auth-reset-password',
    component: () => import('@/pages/admin/auth/reset-password'),
    meta: {
      layout: 'full',
      action: "read",
      resource: 'AuthRoute',
      redirectIfLoggedIn: true,
      roles: ["admin", "anonymous"]
    },
  },
  {
    path: '/auth/verify-phone',
    name: 'auth-verify-phone',
    component: () => import('@/pages/admin/auth/verify-phone'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      // requireAuth: true,
      logPageName: "View Verify Phone Page",
      roles: ["admin"]
    },
  },
  {
    path: '/auth/account',
    name: 'auth-update-profile-account',
    component: () => import('@/pages/admin/auth/profile'),
    meta: {
      action: 'read',
      resource: 'AuthRoute',
      requireAuth: true,
      logPageName: "View Profile Page",
      roles: ["admin"]
    },
  },
  {
    path: '/auth/maintenance-mode',
    name: 'misc-under-maintenance',
    component: () => import('@/pages/admin/auth/maintenance'),
    meta: {
      layout: 'full',
      action: "read",
      resource: 'AuthRoute',
      roles: ["admin"],
      redirectIfLoggedIn: true
    },
  },

  //  Security routes
  {
    path: '/security/mfa',
    name: 'security-mfa',
    component: () => import('@/pages/admin/auth/security/mfa'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      requireAuth: true,
      roles: ["admin", "vendor", "client", "anonymous"]
    },
  },
  {
    path: '/security/mfa-authentication',
    name: 'security-mfa-authentication',
    component: () => import('@/pages/admin/auth/security/authentication'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      redirectIfLoggedIn: true,
      roles: ["admin", "vendor", "client", "anonymous"]
    },
  },
  {
    path: '/security/mfa-activation',
    name: 'security-mfa-activation',
    component: () => import('@/pages/admin/auth/security/activation'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      roles: ["admin", "vendor", "client", "anonymous"]
    },
  },
  {
    path: '/security/authentication-app',
    name: 'security-authentication-app',
    component: () => import('@/pages/admin/auth/security/authentication-app'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      roles: ["admin", "vendor", "client", "anonymous"]
    },
  },
  {
    path: '/security/activation-app',
    name: 'security-activation-app',
    component: () => import('@/pages/admin/auth/security/activation-app'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      roles: ["admin", "vendor", "client", "anonymous"]
    },
  },
  {
    path: '/security/password/expiry',
    name: 'security-password-expiry',
    component: () => import('@/pages/admin/auth/password-expiration'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      requireAuth: true,
      roles: "admin"
    },
  },
  {
    path: '/security/password/change',
    name: 'security-password-change',
    component: () => import('@/pages/admin/auth/change-password'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      requireAuth: true,
      roles: "admin"
    },
  },
]

<template>
  <div>
    <b-row>
      <b-col cols="12" md="5">
        <h1 class="mb-2">
          Generate Offerings Report
        </h1>

        <b-card>
          <b-card-body class="pt-2">
            <b-form-group
              label="Date From"
              label-for="date_from"
            >
              <template #label>
                <span>
                  Date From<span style="color: tomato">*</span>
                </span>
              </template>
              
              <b-form-datepicker
                id="date_from" 
                v-model="dateFrom" 
              />
            </b-form-group>
          </b-card-body>

          <b-card-body class="pt-0">
            <b-form-group
              label="Date To"
              label-for="date_to"
            >
              <template #label>
                <span>
                  Date To<span style="color: tomato">*</span>
                </span>
              </template>

              <b-form-datepicker
                id="date_to" 
                v-model="dateTo" 
                :min="dateFrom"
              />
            </b-form-group>
          </b-card-body>

          <b-card-body class="pt-0">
            <label>Offering Type</label>
            <v-select
              class="filter-select select-size-md"
              placeholder="Offering Type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="offeringTypeFilter"
              :options="offeringTypeOptions"
              :reduce="val => val.value"
              :disabled="loading"
              @input="(val) => offeringTypeFilter = val"
            />
          </b-card-body>

          <b-card-body class="pt-0">
            <label>Church / Church Level</label>
            <v-select
              class="filter-select select-size-md"
              placeholder="Church"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="churchFilter"
              :options="churchOptions"
              :reduce="val => val.value"
              :disabled="loading"
              @input="(val) => churchFilter = val"
            />
          </b-card-body>

          <b-card-body class="pt-0">
            <label>Church Member / Donor</label>
            <v-select
              class="filter-select select-size-md"
              placeholder="Member"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="memberFilter"
              :options="memberOptions"
              :reduce="val => val.value"
              :disabled="loading"
              @input="(val) => memberFilter = val"
            />
          </b-card-body>

          <b-button
            class="mb-2"
            variant="primary"
            block
            size="lg"
            type="submit"
            :disabled="loading || !(dateFrom && dateTo)"
            @click="onGenerateReport"
          >
            Generate Report
          </b-button>

          <div v-if="downloadLink" class="">
            <p class="mb-1 text-warning">
              Report available. Choose an option below
            </p>

            <b-link
              variant="primary"
              size="lg"
              class="btn btn-primary mr-2"
              :href="downloadLink"
              target="_blank"
            >
              Download PDF
            </b-link>

            <b-button
              variant="warning"
              size="md"
              @click="printPDF"
            >
              Print PDF
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { 
  BRow, 
  BCol, 
  BLink,
  BCard, 
  BButton,
  BCardBody, 
  BFormGroup, 
  BFormDatepicker 
} from 'bootstrap-vue';

import { get } from "lodash"
import { base64toBlob } from "@core/utils/utils";

import printJS from 'print-js'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
    vSelect,
    BButton,
    BCardBody,
    BFormGroup,
    BFormDatepicker
  },
  data() {
    return {
      loading: false,
      dateTo: null,
      dateFrom: null,
      churchFilter: null,
      memberFilter: null,
      offeringTypeFilter: null,

      churchOptions: [],
      memberOptions: [],
      offeringTypeOptions: [],

      downloadLink: null,
    }
  },
  created() {
    this.fetchChurches();
    this.fetchMembers();
    this.fetchOfferingTypes();
  },
  methods: {
    async fetchChurches() {
      try {
        this.loading = true; 

        const query = {
          page: 1,
          limit: 10000,
          status: 'approved',
        }

        const request = await this.useJwt().adminService.fetchChurches(query);
        const { data } = request.data;

        this.churchOptions = data.map(opt => ({
          label: `Church Of Pentecost - ${this.getChurchLocationByChurchLevel(opt.church_level, opt)}`,
          value: opt.id
        }));
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
        this.showListTable = true;
      }
    },
    async fetchMembers() {
      try {
        this.loading = true; 

        const query = {
          page: 1,
          limit: 10000,
        }

        const request = await this.useJwt().adminService.fetchChurchMembers(query);
        const { data } = request.data;

        this.memberOptions = data.map(opt => ({
          label: `${opt.full_name} | ${opt.phone}`,
          value: opt.id
        }));
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
        this.showListTable = true;
      }
    },
    async fetchOfferingTypes() {
      try {
        this.loading = true; 

        const query = {
          limit: 1000,
          page: 1,
          status: 'active',
        }

        const response = await this.useJwt().adminService.fetchOfferingTypes(query)

        const { data } = response.data;

        this.offeringTypeOptions = data.map(opt => ({
          label: opt.title,
          value: opt.id
        }));
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
        this.showListTable = true;
      }
    },
    async onGenerateReport() {
      try {
        this.loading = true;
        this.downloadLink = null;

        const query = {
          to: this.dateTo,
          from: this.dateFrom,
          member_id: this.memberFilter,
          church_id: this.churchFilter,
          offering_type: this.offeringTypeFilter,
        }

        const result = await this.$swal({
          title: 'Generate Report?',
          text: "Do you want to generate report with selected query?",
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        const response = await this.useJwt().adminService.generateTransactionsReport(query);
        const { pdf } = response.data.data;

        const url = URL.createObjectURL(base64toBlob(pdf));
        this.downloadLink = url;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message

        this.$nextTick(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error_message || 'Unable to generate report. Try again later.'
            },
          });
        })
      } finally {
        this.loading = false;
      }
    },
    printPDF() {
      printJS({
        printable: this.downloadLink,
        type: "pdf"
      })
    },

  }
}
</script>

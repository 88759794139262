<template>
  <b-overlay :show="loading" opacity="0.5">
    <add-or-update-church-map-modal
      :is-add-or-update-active.sync="isAddOrUpdateActive"
      :update-data="updateData"
      @refetchData="fetchAllData"
      @resetUpdateData="resetUpdateData"
    />
    <div>
      <list-table
        ref="users_table_ref"
        :records="users"
        :columns="tableColumns"
        :total-records="totalRecords"
        :search-filter.sync="searchFilter"
        :current-page-number.sync="currentPage"
        :show-export-button="false"
        :show-import-button="false"
      >
          <template #action_button>
            <b-button
              v-if="can('church_map:CanAddNew')"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              :disabled="false"
              @click="onEdit({})"
            >
              <span class="align-middle text-lg">Add Church Map</span>
            </b-button>
          </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="onEdit(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="onDelete(data.item._id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </list-table>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BAlert,
  BLink,
  BTable,
  BMedia,
  BAvatar,
  BButton,
  BFormFile,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
} from "bootstrap-vue";
import { get, debounce } from "lodash";

import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

import ListTable from "@/@core/components/ListTable/ListTable.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AddOrUpdateChurchMapModal from "@core/components/church-crud/AddOrUpdateChurchMapModal.vue";

const watchHandler = {
  handler() {
    this.debouncedFetchUsersData();
  },
  immediate: false,
};
export default {
  components: {
    ListTable,

    BOverlay,
    BCard,
    BTable,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BLink,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    AddOrUpdateChurchMapModal,

    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    clientGroupId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      showFilter: false,
      showListTable: false,
      statusFilter: null,
      userTypeFilter: "provider",
      searchFilter: "",
      users: [],
      importType: "",
      updateData: {},
      tableColumns: [
        { key: "region", sortable: false },
        { key: "area", sortable: false },
        { key: "district", sortable: false },
        { key: "assembly", sortable: false },
        { key: "assembly_type", sortable: false },
        { key: "actions" },
      ],
      debouncedFetchUsersData: () => {},
      isAddOrUpdateActive: false
    };
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value) {
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value);
      },
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value) {
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value);
      },
    },
    totalRecords: {
      get() {
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value) {
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value);
      },
    },
    dataMeta() {
      const localItemsCount = this.users.length || 0;
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalRecords,
      };
    },
  },
  watch: {
    perPage: {
      handler() {
        this.currentPage = 1;
        this.debouncedFetchUsersData();
      },
      immediate: false,
    },
    currentPage: watchHandler,
    statusFilter: watchHandler,
    searchFilter: watchHandler,
    userTypeFilter: watchHandler,
    isAddOrUpdateActive: {
      handler(value) {
        if (!value) {
          this.updateData = {}
        }
      }
    }
  },
  created() {
    this.debouncedFetchUsersData = debounce(this.fetchAllData, 500);

    const {
      page = 1,
      search = "",
      limit = this.perPage,
    } = this.$route.query;

    this.currentPage = +page;
    this.searchFilter = search;
    this.perPage = +limit;

    this.debouncedFetchUsersData()
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true;

        const query = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.searchFilter
        };

        this.$router.push({ query }).catch(() => {});

        const request = await this.useJwt().adminService.fetchChurchMap(query);
        const { data, pagination } = request.data;

        this.totalRecords = pagination.totalRecords;
        this.perPage = pagination.limit;
        this.users = data;
      } catch (error) {
        const error_message =
          get(error, "response.data.message") || error.message;
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
        this.showListTable = true;
      }
    },
    async onDelete(id) {
      try {
        this.loading = true;

        const result = await this.$swal({
          title: "Confirm Delete?",
          text: `Do you want to delete this churm map data?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, proceed.",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        await this.useJwt().adminService.deleteChurchMap(id);
        await this.fetchAllData();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success!",
            icon: "CheckCircleIcon",
            variant: "success",
            text: 'Church map data deleted',
          },
        });
      } catch (error) {
        const error_message =
          get(error, "response.statusText") || error.message;

        this.$nextTick(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error!",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: error_message,
            },
          });
        });
      } finally {
        this.loading = false;
      }
    },
    onEdit(row) {
      this.updateData = row
      this.isAddOrUpdateActive = true
    },
    resetUpdateData() {
      this.updateData = {}
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
.width-100 {
  width: 100px;
}
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
.loan-list-tb-row {
  cursor: pointer !important;
}
</style>
